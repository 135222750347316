import React, { Component } from "react";
import { Card, CardBody, CardText } from "mdbreact";
import Navbar from "./Navbar";
import Footer from "./Footer";
import ShareLinks from "./ShareLinks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import resultsdr from "../assets/img/results-dr.svg";
import resultsnextsteps from "../assets/img/results-next-steps.svg";

class Results extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    console.log("Results page -> PROPS -> ", this.props);
    return (
      <div className="secondary">
        <Navbar />
        <div className="secondary-content">
          <div className="container">
            <div className="row">
              <div className="col mt-4 mb-4 progress-tabs">
                <div className="wrapper light tab-content">
                  <div className="col-lg-10 col-12">
                    <h2 className="light mb-0">
                      {this.props.location.state
                        && this.props.location.state != undefined
                        &&
                        "Thank you for filling out the survey!"
                      }
                    </h2>
                    <h2 className="underline light mt-5 mb-4">
                      <span>Next steps</span>
                    </h2>
                    {this.props.location.state
                      && this.props.location.state != undefined
                      && this.props.location.state.surveyData.selectedSymptoms
                      && this.props.location.state.surveyData.selectedSymptoms
                        .length > 0 ? (
                        <Card className="col-12 p-0">
                          <CardBody>
                            <div className="d-inline-flex">
                              <div className="align-self-center hidden-xs-down">
                                <img
                                  className="mr-3"
                                  alt="Consult Dr."
                                  src={resultsdr}
                                />
                              </div>
                              <div className="align-self-center">
                                <h4 className="secondary mb-2">
                                  Consult with your doctor
                                </h4>
                                <p className="mb-2">
                                  Due to your symptoms, we recommend you see a
                                  doctor for further evaluation.
                                </p>
                                <p className="mb-0 xsmall">
                                  All feedback we provide is educational and
                                  meant to help you learn more about vaping and
                                  lung illness. This is not medical advice. Call
                                  your doctor for medical advice or call 911 if
                                  you need immediate assistance.
                                </p>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      ) :
                      (
                        <Card className="col-12 p-0">
                          <CardBody>
                            <div className="d-flex">
                              <div className="align-self-center hidden-xs-down">
                                <img
                                  className="mr-3"
                                  alt="Next Steps"
                                  src={resultsnextsteps}
                                />
                              </div>
                              <div className="flex-fill align-self-center">
                                <h4 className="secondary mb-2">
                                  Try to stop using vaping products, particularly
                                  those containing THC.
                                </h4>
                                <p className="mb-2">
                                  See your doctor if you begin to experience any
                                  of the following symptoms:
                                </p>
                                <span className="flex-column flex-md-row d-flex">
                                  <ul className="mr-4 mb-0 flex-fill d-flex flex-column">
                                    <li>Cough</li>
                                    <li>Difficulty breathing</li>
                                    <li>Chest pain</li>
                                  </ul>
                                  <ul className="d-flex mr-4 mb-0 flex-fill flex-column">
                                    <li>Nausea</li>
                                    <li>Vomiting</li>
                                    <li>Diarrhea</li>
                                  </ul>
                                  <ul className="d-flex flex-column mb-0 mr-4 flex-fill">
                                    <li>Feeling tired</li>
                                    <li>Fever</li>
                                    <li>Stomach Pain</li>
                                  </ul>
                                </span>
                                <p className="mt-3 mb-2">
                                  Learn more about what you can do to{" "}
                                  <a
                                    className="link"
                                    href="https://www.cdc.gov/tobacco/basic_information/e-cigarettes/severe-lung-disease/need-to-know/index.html#symptoms"
                                  >
                                    protect yourself{" "}
                                    <FontAwesomeIcon
                                      icon={["far", "arrow-right"]}
                                    />
                                  </a>
                                </p>
                                <p className="mb-0 xsmall">
                                  All feedback we provide is educational and meant
                                  to help you learn more about vaping and lung
                                  illness. This is not medical advice. Call your
                                  doctor for medical advice or call 911 if you
                                  need immediate assistance.
                                </p>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      )
                    }

                    <h2 className="mt-5 underline light mb-4">
                      <span>Your survey answers</span>
                    </h2>
                    <h5 className="secondary">History</h5>
                    <p className="small mb-2">
                      When did you start vaping?
                      <br />
                      {this.props.location.state
                        && this.props.location.state != undefined
                        && this.props.location.state.surveyData
                          .whenStartedVapingOption &&
                        this.props.location.state.surveyData
                          .whenStartedVapingOption != "" ? (
                          <span className="answer">
                            {this.props.location.state.surveyData.whenStartedVapingOption.replace(
                              /\_/g,
                              " "
                            )}
                          </span>
                        ) : (
                          <span className="answer">Not answered</span>
                        )}
                    </p>
                    <p className="mb-2 small">
                      In the past three months, how many times did you vape on a
                      typical day?
                      <br />
                      {this.props.location.state
                        && this.props.location.state != undefined
                        && this.props.location.state.surveyData
                          .typicalVapingPerDayOption &&
                        this.props.location.state.surveyData
                          .typicalVapingPerDayOption != "" ? (
                          <span className="answer">
                            {this.props.location.state.surveyData.typicalVapingPerDayOption.replace(
                              /\_/g,
                              " "
                            )}
                          </span>
                        ) : (
                          <span className="answer">Not answered</span>
                        )}
                    </p>
                    <p className="mb-2 small">
                      When did you last vape?
                      <br />
                      {this.props.location.state
                        && this.props.location.state != undefined
                        && this.props.location.state.surveyData.lastVapedOption &&
                        this.props.location.state.surveyData.lastVapedOption !=
                        "" ? (
                          <span className="answer">
                            {this.props.location.state.surveyData.lastVapedOption.replace(
                              /\_/g,
                              " "
                            )}
                          </span>
                        ) : (
                          <span className="answer">Not answered</span>
                        )}
                    </p>
                    <h5 className="secondary mt-4">Symptoms</h5>
                    <p className="mb-2 small">
                      Have you noticed a change in how you are feeling since you
                      started vaping? Select all symptoms you have experienced
                      that you think were caused by vaping.
                      <br />
                      {this.props.location.state
                        && this.props.location.state != undefined
                        && this.props.location.state.surveyData.selectedSymptoms &&
                        this.props.location.state.surveyData.selectedSymptoms
                          .length > 0 ? (
                          <span className="answer">
                            {this.props.location.state.surveyData.selectedSymptoms.map(
                              symptom => {
                                return symptom + ", ";
                              }
                            )}
                          </span>
                        ) : (
                          <span className="answer">
                            I don't have any symptoms
                        </span>
                        )}
                    </p>

                    {this.props.location.state
                      && this.props.location.state != undefined
                      && this.props.location.state.surveyData.selectedSymptoms &&
                      this.props.location.state.surveyData.selectedSymptoms
                        .length > 0 ? (
                        <p className="mb-2 small">
                          When did your symptoms start?
                        <br />
                          {this.props.location.state.surveyData
                            .symptoms_started &&
                            this.props.location.state.surveyData.symptoms_started !=
                            "" ? (
                              <span className="answer">
                                {this.props.location.state.surveyData.symptoms_started.replace(
                                  /\_/g,
                                  " "
                                )}
                              </span>
                            ) : (
                              <span className="answer">Not answered</span>
                            )}
                        </p>
                      ) : (
                        ""
                      )}

                    {this.props.location.state
                      && this.props.location.state != undefined
                      && this.props.location.state.surveyData.selectedSymptoms &&
                      this.props.location.state.surveyData.selectedSymptoms
                        .length > 0 ? (
                        <p className="mb-2 small">
                          Have you seen a doctor for these symptoms?
                        <br />
                          {this.props.location.state.surveyData
                            .consulted_doctor &&
                            this.props.location.state.surveyData.consulted_doctor !=
                            "" ? (
                              <span className="answer">{this.props.location.state.surveyData.consulted_doctor}</span>
                            ) : (
                              <span className="answer">Not answered</span>
                            )}
                        </p>
                      ) : (
                        ""
                      )}

                    <h5 className="mt-4 secondary">Your Details</h5>
                    <p className="mb-2 small">
                      How old are you?
                      <br />
                      {this.props.location.state
                        && this.props.location.state != undefined
                        && this.props.location.state.surveyData.age &&
                        this.props.location.state.surveyData.age != "" ? (
                          <span className="answer">
                            {this.props.location.state.surveyData.age}
                          </span>
                        ) : (
                          <span className="answer">Not answered</span>
                        )}
                    </p>
                    <p className="mb-2 small">
                      What is your biological sex?
                      <br />
                      {this.props.location.state
                        && this.props.location.state != undefined
                        && this.props.location.state.surveyData.sex &&
                        this.props.location.state.surveyData.sex != "" ? (
                          <span className="answer">
                            {this.props.location.state.surveyData.sex}
                          </span>
                        ) : (
                          <span className="answer">Not answered</span>
                        )}
                    </p>
                    <p className="mb-2 small">
                      Did you have a chronic medical condition before you
                      started vaping?
                      <br />
                      {this.props.location.state
                        && this.props.location.state != undefined
                        && this.props.location.state.surveyData
                          .chronic_condition_flag &&
                        this.props.location.state.surveyData
                          .chronic_condition_flag != "" ? (
                          <span className="answer">
                            {
                              this.props.location.state.surveyData
                                .chronic_condition_flag
                            }
                          </span>
                        ) : (
                          <span className="answer">Not answered</span>
                        )}
                    </p>
                    {this.props.location.state
                      && this.props.location.state != undefined
                      && this.props.location.state.surveyData.chronic_condition_flag
                      && this.props.location.state.surveyData.chronic_condition_flag == "yes"
                      && (
                        <p className="mb-2 small">
                          Please describe your chronic medical condition.
                        <br />
                          {this.props.location.state.surveyData
                            .chronic_condition_desc &&
                            this.props.location.state.surveyData
                              .chronic_condition_desc != "" ? (
                              <span className="answer">
                                {
                                  this.props.location.state.surveyData
                                    .chronic_condition_desc
                                }
                              </span>
                            ) : (
                              <span className="answer">Not answered</span>
                            )}
                        </p>
                      )
                    }

                    <h5 className="secondary mt-4">Product</h5>
                    <p className="mb-2 small">
                      Where do you buy your vaping products?
                      <br />
                      {this.props.location.state
                        && this.props.location.state != undefined
                        && this.props.location.state.surveyData.vapingProductsBroughtFromList
                        && this.props.location.state.surveyData.vapingProductsBroughtFromList.length > 0 ? (
                          <span className="answer">
                            {this.props.location.state.surveyData.vapingProductsBroughtFromList.map(
                              broughtFrom => {
                                return broughtFrom.replace(/\_/g, " ") + ", ";
                              }
                            )}
                          </span>
                        ) : (
                          <span className="answer">Not answered</span>
                        )}
                    </p>

                    {this.props.location.state
                      && this.props.location.state != undefined
                      && this.props.location.state.surveyData.vapingProductsBroughtFromList
                      && this.props.location.state.surveyData.vapingProductsBroughtFromList.length > 0 ? (
                        <div>
                          {this.props.location.state.surveyData.vapingProductsBroughtFromList.map(
                            broughtFrom => {
                              if (broughtFrom == "Other_locations") {
                                return (this.props.location.state.surveyData.vapingProductsOtherText
                                  && this.props.location.state.surveyData.vapingProductsOtherText != "" ?
                                  <p className="mb-2 small">
                                    Please list all other locations you have bought vaping products from. Use a comma to
                                    separate locations.<br />
                                    <span className="answer">
                                      {this.props.location.state.surveyData.vapingProductsOtherText}
                                    </span>
                                  </p>
                                  :
                                  <p className="mb-2 small">
                                    Please list all other locations you have bought vaping products from. Use a comma to
                                    separate locations.<br />
                                    <span className="answer"> Not answered</span>
                                  </p>
                                )
                              } else {
                                return
                              }
                            }
                          )}
                        </div>
                      ) : (
                        null
                      )}

                    <p className="mb-2 small">
                      What vaping products have you used?
                      <br />
                      {this.props.location.state
                        && this.props.location.state != undefined
                        && this.props.location.state.surveyData
                          .vapingProductsList &&
                        this.props.location.state.surveyData.vapingProductsList
                          .length > 0 ? (
                          <span className="answer">
                            {this.props.location.state.surveyData.vapingProductsList.map(
                              product => {
                                return product.replace(/\_/g, " ") + ", ";
                              }
                            )}
                          </span>
                        ) : (
                          <span className="answer">Not answered</span>
                        )}
                    </p>


                    {this.props.location.state
                      && this.props.location.state != undefined
                      && this.props.location.state.surveyData.vapingProductsList
                      && this.props.location.state.surveyData.vapingProductsList.length > 0 ? (
                        <div>
                          {this.props.location.state.surveyData.vapingProductsList.map(
                            product => {
                              if (product == "Flavored") {
                                return (this.props.location.state.surveyData.vapingFlavor
                                  && this.props.location.state.surveyData.vapingFlavor != "" ?
                                  <p className="mb-2 small">
                                    Please list the flavors you have used. Use a comma to
                                    separate flavors.<br />
                                    <span className="answer">
                                      {this.props.location.state.surveyData.vapingFlavor}
                                    </span>
                                  </p>
                                  :
                                  <p className="mb-2 small">
                                    Please list the flavors you have used. Use a comma to
                                    separate flavors.<br />
                                    <span className="answer"> Not answered</span>
                                  </p>
                                )
                              } else {
                                return
                              }
                            }
                          )}
                        </div>
                      ) : (
                        null
                      )}

                    {this.props.location.state
                      && this.props.location.state != undefined
                      && this.props.location.state.surveyData.vapingProductsList
                      && this.props.location.state.surveyData.vapingProductsList.length > 0 ? (
                        <div>
                          {this.props.location.state.surveyData.vapingProductsList.map(
                            product => {
                              if (product == "Homemade") {
                                return (this.props.location.state.surveyData.vapingIngredientsHomemade
                                  && this.props.location.state.surveyData.vapingIngredientsHomemade != "" ?
                                  <p className="mb-2 small">
                                    Please list the ingredients in the homemade product.
                                    Use a comma to separate ingredients.<br />
                                    <span className="answer">
                                      {this.props.location.state.surveyData.vapingIngredientsHomemade}
                                    </span>
                                  </p>
                                  :
                                  <p className="mb-2 small">
                                    Please list the ingredients in the homemade product.
                                    Use a comma to separate ingredients.<br />
                                    <span className="answer"> Not answered</span>
                                  </p>
                                )
                              } else {
                                return
                              }
                            }
                          )}
                        </div>
                      ) : (
                        null
                      )}

                    {this.props.location.state
                      && this.props.location.state != undefined
                      && this.props.location.state.surveyData.vapingProductsList
                      && this.props.location.state.surveyData.vapingProductsList.length > 0 ? (
                        <div>
                          {this.props.location.state.surveyData.vapingProductsList.map(
                            product => {
                              if (product == "Other_products") {
                                return (this.props.location.state.surveyData.vapingProductUsageOtherText
                                  && this.props.location.state.surveyData.vapingProductUsageOtherText != "" ?
                                  <p className="mb-2 small">
                                    Please list all other products you have used. Use a comma to
                                    separate products.<br />
                                    <span className="answer">
                                      {this.props.location.state.surveyData.vapingProductUsageOtherText}
                                    </span>
                                  </p>
                                  :
                                  <p className="mb-2 small">
                                    Please list all other products you have used. Use a comma to
                                    separate products.<br />
                                    <span className="answer"> Not answered</span>
                                  </p>
                                )
                              } else {
                                return
                              }
                            }
                          )}
                        </div>
                      ) : (
                        null
                      )}
                    <p className="mb-2 small">
                      What vape brands do you use?
                      <br />
                      {this.props.location.state
                        && this.props.location.state != undefined
                        && this.props.location.state.surveyData.vapingBrands &&
                        this.props.location.state.surveyData.vapingBrands.length >
                        0 ? (
                          <span className="answer">
                            {this.props.location.state.surveyData.vapingBrands.map(
                              brand => {
                                return brand.replace(/\_/g, " ") + ", ";
                              }
                            )}
                          </span>
                        ) : (
                          <span className="answer">Not answered</span>
                        )}
                    </p>

                    {this.props.location.state
                      && this.props.location.state != undefined
                      && this.props.location.state.surveyData.vapingBrands
                      && this.props.location.state.surveyData.vapingBrands.length > 0 ? (
                        <div>
                          {this.props.location.state.surveyData.vapingBrands.map(
                            brand => {
                              if (brand == "Other") {
                                return (this.props.location.state.surveyData.vapingBrandsOtherText
                                  && this.props.location.state.surveyData.vapingBrandsOtherText != "" ?
                                  <p className="mb-2 small">
                                    Please list all other vape brands you have used. Use a comma to
                                    separate brands.<br />
                                    <span className="answer">
                                      {this.props.location.state.surveyData.vapingBrandsOtherText}
                                    </span>
                                  </p>
                                  :
                                  <p className="mb-2 small">
                                    Please list all other vape brands you have used. Use a comma to
                                    separate brands.<br />
                                    <span className="answer"> Not answered</span>
                                  </p>
                                )
                              } else {
                                return
                              }
                            }
                          )}
                        </div>
                      ) : (
                        null
                      )}


                    <p className="mb-2 small">
                      Have you noticed anything unusual about your vaping
                      product?
                      <br />
                      {this.props.location.state
                        && this.props.location.state != undefined
                        && this.props.location.state.surveyData.unusualSmellFromProduct_flag &&
                        this.props.location.state.surveyData.unusualSmellFromProduct_flag != "" ? (
                          <span className="answer">
                            {this.props.location.state.surveyData.unusualSmellFromProduct_flag}
                          </span>
                        ) : (
                          <span className="answer">Not answered</span>
                        )}
                    </p>

                    {this.props.location.state
                      && this.props.location.state != undefined
                      && this.props.location.state.surveyData.unusualSmellFromProduct_flag
                      && this.props.location.state.surveyData.unusualSmellFromProduct_flag == "yes"
                      && this.props.location.state.surveyData.unusualSmellFromProduct_desc
                      && this.props.location.state.surveyData.unusualSmellFromProduct_desc != "" ? (
                        <p className="mb-2 small">
                          Please describe what was unusual about your vaping
                          product.
                          <br />
                          <span className="answer">
                            {this.props.location.state.surveyData.unusualSmellFromProduct_desc}
                          </span>
                        </p>
                      ) : (
                        null
                      )}


                    <h5 className="secondary mt-4">Additional Info</h5>
                    <p className="mb-2 small">
                      Please provide your zipcode
                      <br />
                      {this.props.location.state
                        && this.props.location.state != undefined
                        && this.props.location.state.surveyData.zipcode
                        && this.props.location.state.surveyData.zipcode != ""
                        ? (
                          <span className="answer">
                            {this.props.location.state.surveyData.zipcode}
                          </span>
                        ) : (
                          <span className="answer">
                            Not answered
                                </span>
                        )
                      }
                    </p>

                    <p className="mb-2 small">
                      Can we contact you for more information about your
                      experience?
                      <br />
                      {this.props.location.state
                        && this.props.location.state != undefined
                        && this.props.location.state.surveyData.contactConsentFlag
                        && this.props.location.state.surveyData.contactConsentFlag != ""
                        ? (
                          <span className="answer">
                            {this.props.location.state.surveyData.contactConsentFlag}
                          </span>
                        ) : (
                          <span className="answer">
                            Not answered
                          </span>
                        )
                      }
                    </p>

                    {this.props.location.state
                      && this.props.location.state != undefined
                      && this.props.location.state.surveyData.contactConsentFlag
                      && this.props.location.state.surveyData.contactConsentFlag == "yes"
                      && this.props.location.state.surveyData.email
                      && this.props.location.state.surveyData.email != ""
                      ? (
                        <p className="mb-2 small">
                          Please provide your email so we can contact you.
                          <br />
                          <span className="answer">
                            {this.props.location.state.surveyData.email}
                          </span>
                        </p>
                      ) :
                      null
                    }

                    <p className="mb-2 small">
                      Do you have any additional comments?
                      <br />
                      {this.props.location.state
                        && this.props.location.state != undefined
                        && this.props.location.state.surveyData.additionalComments
                        && this.props.location.state.surveyData.additionalComments != ""
                        ? (
                          <span className="answer">
                            {this.props.location.state.surveyData.additionalComments}
                          </span>
                        ) : (
                          <span className="answer">
                            Not answered
                          </span>
                        )
                      }
                    </p>

                    <h2 className="mt-5 underline light mb-4">
                      <span>The latest on the vaping outbreak</span>
                    </h2>
	           <p>
            Monitor the outbreak and view the latest number of <a 
                        className="link"
                        href="https://yhswen.shinyapps.io/vaping"
                        target="_blank"
	    >lung injury cases and deaths  <FontAwesomeIcon icon={["far", "arrow-right"]} /></a> 
                    </p>
                    <p>
                      You can learn more about vaping and lung injury by visiting the{" "}
                      <a
                        className="link"
                        href="https://www.cdc.gov/tobacco/basic_information/e-cigarettes/severe-lung-disease.html"
                        target="_blank"
                      >
                        CDC’s website{" "}
                        <FontAwesomeIcon icon={["far", "arrow-right"]} />
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ShareLinks />
        <Footer />
      </div>
    );
  }
}

export default Results;
