import React, { Component } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";

class Terms extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="secondary">
        <Navbar />
        <div className="mt-5 mb-5 pt-5 pb-5 secondary-content">
          <div className="container pt-5 pb-5 mb-5">
            <div className="row">
              <div className="col col-8 mr-auto ml-auto">
                <h3 className="text-center mb-4">Privacy policy</h3>
                <p>
                  We take the security and confidentiality of your information
                  seriously. As we describe below, your participation in this
                  project will remain confidential. Submissions will be
                  anonymized and posted in aggregate with other submissions. IP
                  addresses are routinely recorded but will also remain
                  confidential. Your contact information will not be shared with
                  any third party except as required by law.
                </p>

                <p>
                  We collect personal information such as, but not limited to,
                  your email address, age, gender and Internet Protocol address.
                  We reserve the right to use this information internally,
                  including but not limited to research purposes, quality
                  assurance purposes, and correspondence with you, but we will
                  not sell, share, rent, or otherwise reveal this information to
                  any third party except as required by law, or to address
                  issues of noncompliance. We may share aggregate data with
                  third parties in a manner that will not identify you.
                </p>

                <h3 className="text-center mb-4 mt-5">
                  Terms of participation
                </h3>
                <p>
                  By submitting information you grant a perpetual, royalty-free,
                  unconditional license to Boston Children's Hospital and any
                  successor organizations to publish your contribution in
                  aggregate with other submissions on the site and all
                  affiliated sites, as well as disseminate it to other parties
                  via RSS or other media, and to discuss or reference it in any
                  publications related to or arising out of You Vape. You also
                  agree that You Vape has the right, but not the obligation, to
                  edit or remove any contribution in You Vape's sole discretion.
                </p>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Terms;
