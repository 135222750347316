export default {
  dosingRecommendations: [],
  symptomDetails: {},
  vapingProgressTabIndex: 1,
  vapingRecommendations: [],
  symptomsDisplayFlag: true,
  historyDetails: {
    lastVapedOption: "",
    typicalVapingPerDayOption: "",
    whenStartedVapingOption: ""
  },
  symptomsOptions: {
    symptoms_started: "",
    consulted_doctor: ""
  },
  participantDetails: {
    age: "",
    sex: "",
    chronic_condition: "",
    chronic_condition_desc: ""
  },
  productDetails: {
    where_did_you_buy: "",
    what_is_vaping_prod_used: "",
    vape_brands: "",
    flavor: "",
    vaping_products_brought_from_other_text: "",
    vaping_products_other_text: "",
    vaping_brands_other_text: "",
    homemade_ingredients: "",
    unusual_smell_flag: "",
    unusual_characters_desc: ""
  },
  additionalDetails: {
    zipcode: "",
    contact_consent_flag: "",
    email: "",
    additional_comments: ""
  },

  referral: '',
  formErrors: {
    err_started_vaping: false,
    err_typical_day_vaping: false,
    err_last_vaped: false,
    err_selecting_symptoms: false,
    err_selecting_symptoms_started_time: false,
    err_selecting_vaping_products: false,
    err_age_format: false,
    err_email_format: false,
    err_zipcode_format: false
  }
}
