import React, { Component } from 'react';
import ReactGA from 'react-ga';

if (process.env.REACT_APP_NODE_ENV == "test") {
    ReactGA.initialize('UA-150281148-1');
} else if (process.env.REACT_APP_NODE_ENV == "prod") {
    ReactGA.initialize('UA-150281148-2');
}

class GoogleAnalyticsCode extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        ReactGA.event({
            category: this.props.GA_Category,
            action: this.props.GA_Action,
            label: this.props.GA_Label,
            value: this.props.GA_Value
        })
    }

    render() {
        return (
            <div></div>
        );
    }
}

export default GoogleAnalyticsCode;

