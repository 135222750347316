import React from "react";
import { Navbar, NavbarNav, NavLink, NavItem, Fa } from "mdbreact";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Footer extends React.Component {
  render() {
    return (
      <div className="wrapper footer">
        <div className="container">
          <div className="row no-gutters">
            <div className="col-12 col-lg-4">
              <p>
                <FontAwesomeIcon icon={["far", "copyright"]} />
                <span className="pl-1">2019 Boston Children's Hospital</span>
              </p>
              <p className="small">
                401 Park Drive
                <br />
                Boston, MA 02215
              </p>
            </div>
            <div className="col-12 col-lg-4 mt-md-0 mt-3">
              <p>Support</p>
              <p className="small">
                <a href="/contact">Contact Us</a>
                <a href="/faq">FAQ</a>
                <a href="/terms">Terms and Privacy</a>
              </p>
            </div>

            <div className="mt-md-0 mt-3 col-12 col-lg-4">
              <p>Partner Public Health Projects</p>
              <p className="small">
                <a href="https://thermia.io/checkfever" target="_blank">Thermia</a>
                <a href="https://flunearyou.org" target="_blank">Flu Near You</a>
                <a href="https://healthmap.org/en" target="_blank">HealthMap</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
