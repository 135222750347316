import React from "react";
import vomiting from "../assets/img/symptoms/nausea.svg";
import vomitingwhite from "../assets/img/symptoms/vomiting-white.svg";
class SymptomNausea extends React.Component {
  render() {
    return (
      <div className="symptom-group d-flex flex-column justify-content-center">
        <img src={vomiting} className="symptom-icon" alt="Vomiting" />
        <img
          src={vomitingwhite}
          className="white-outline symptom-icon"
          alt="Vomiting"
        />
        <h5 className="mt-3 mb-0">Nausea</h5>
      </div>
    );
  }
}

export default SymptomNausea;
