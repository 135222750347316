export const GET_DOSING_RECOMMENDATIONS_SUCCESS = 'GET_DOSING_RECOMMENDATIONS_SUCCESS';
export const GET_FEVER_RECOMMENDATIONS_SUCCESS = 'GET_FEVER_RECOMMENDATIONS_SUCCESS';
export const STORE_TEMPERATURE = 'STORE_TEMPERATURE';
export const STORE_SYMPTOMS = 'STORE_SYMPTOMS';
export const STORE_PATIENT_DETAILS = 'STORE_PATIENT_DETAILS';
export const RESET_DOSING_RECOMMENDATIONS = 'RESET_DOSING_RECOMMENDATIONS';
export const RESET_FEVER_RECOMMENDATIONS = 'RESET_FEVER_RECOMMENDATIONS';
export const FEVER_RECOMMENDATION_MOVE_NEXT = 'MOVE_NEXT_FEVER_RECOMMENDATION_MOVE_NEXT';
export const FEVER_RECOMMENDATION_MOVE_BACK = 'MOVE_NEXT_FEVER_RECOMMENDATION_MOVE_BACK';
export const FEVER_TEMPERATURE_REQUIRED_ERROR = 'FEVER_TEMPERATURE_REQUIRED_ERROR';
export const FEVER_PATIENT_AGE_REQUIRED_ERROR = 'FEVER_PATIENT_AGE_REQUIRED_ERROR';

export const POST_SURVEY_DATA_SUCESS = 'POST_SURVEY_DATA_SUCESS';
export const RESET_VAPING_SURVEY = 'RESET_VAPING_SURVEY';

export const SYMPTOMS_DISPLAY_FLAG = 'SYMPTOMS_DISPLAY_FLAG';

export const NEW_USER_SESSION_SUCCESS = 'NEW_USER_SESSION_SUCCESS';
export const VAPING_SURVEY_MOVE_NEXT = 'VAPING_SURVEY_MOVE_NEXT';
export const VAPING_SURVEY_MOVE_BACK = 'VAPING_SURVEY_MOVE_BACK';
export const STORE_VAPING_HISTORY_DETAILS = 'STORE_VAPING_HISTORY_DETAILS';
export const STORE_VAPING_SYMPTOMS_DETAILS = 'STORE_VAPING_SYMPTOMS_DETAILS';
export const STORE_VAPING_SYMPTOMS_OPTIONS = 'STORE_VAPING_SYMPTOMS_OPTIONS';
export const STORE_USER_DETAILS = 'STORE_USER_DETAILS';
export const STORE_VAPING_PRODUCT_DETAILS = 'STORE_VAPING_PRODUCT_DETAILS';
export const STORE_ADDITIONAL_DETAILS = 'STORE_ADDITIONAL_DETAILS';

export const ERROR_LAST_TIME_VAPED = 'ERROR_LAST_TIME_VAPED';
export const ERROR_TYPICAL_DAY_VAPING = 'ERROR_TYPICAL_DAY_VAPING';
export const ERROR_START_VAPING_QST = 'ERROR_START_VAPING_QST';
export const ERROR_VERIFY_AGE_FORMAT = 'ERROR_VERIFY_AGE_FORMAT';
export const ERROR_VERIFY_EMAIL_FORMAT = 'ERROR_VERIFY_EMAIL_FORMAT';
export const ERROR_VERIFY_ZIPCODE_FORMAT = 'ERROR_VERIFY_ZIPCODE_FORMAT';


export const ERROR_SELECTING_SYMPTOMS = 'ERROR_SELECTING_SYMPTOMS';
export const ERROR_SELECTING_SYMPTOMS_STARTED_TIME = 'ERROR_SELECTING_SYMPTOMS_STARTED_TIME';

export const ERROR_SELECTING_VAPING_PRODUCTS = 'ERROR_SELECTING_VAPING_PRODUCTS';