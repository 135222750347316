import React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import Home from "./components/Home";
import ScrollToTop from "./components/ScrollToTop";
import Survey from "./components/Survey";
import Results from "./components/Results";
import Faq from "./components/Faq";
import Terms from "./components/Terms";
import Contact from "./components/Contact";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faFilter, faMapMarkerAlt } from "@fortawesome/pro-solid-svg-icons";
import {
  faFacebookF,
  faTwitter,
  faLinkedinIn
} from "@fortawesome/free-brands-svg-icons";
import {
  faExternalLinkSquareAlt,
  faArrowLeft,
  faCopyright,
  faBars,
  faArrowRight
} from "@fortawesome/pro-regular-svg-icons";
import {
  faArrowCircleLeft,
  faArrowCircleRight
} from "@fortawesome/pro-light-svg-icons";

library.add(
  faFacebookF,
  faFilter,
  faLinkedinIn,
  faMapMarkerAlt,
  faBars,
  faTwitter,
  faExternalLinkSquareAlt,
  faCopyright,
  faArrowLeft,
  faArrowRight,
  faArrowCircleLeft,
  faArrowCircleRight
);

class App extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <ScrollToTop>
          <div>
            <Route
              path="/cases"
              component={() => {
                window.location.href = "https://yhswen.shinyapps.io/vaping";
                return null;
              }}
            />
            <Route exact path="/" component={Home} />
            <Route
              exact
              path="/first"
              render={props => <Home {...props} referral="first" />}
            />
            <Route path="/survey" component={Survey} />
            <Route path="/results" component={Results} />
            <Route path="/faq" component={Faq} />
            <Route path="/contact" component={Contact} />
            <Route path="/terms" component={Terms} />
          </div>
        </ScrollToTop>
      </BrowserRouter>
    );
  }
}

export default App;
