import React from "react";
import {
  MDBSideNav,
  MDBNavbar,
  MDBNavbarBrand,
  Collapse,
  Container,
  Fa
} from "mdbreact";
import NavbarItem from "./NavbarItem.js";

class Topnav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
      isWideEnough: false,
      isLeftOpen: false
    };
  }

  // Slide out buttons event handlers
  handleToggleClickA = () => {
    this.setState({
      isLeftOpen: !this.state.isLeftOpen
    });
  };

  render() {
    const { isLeftOpen } = this.state;
    return (
      <div>
        <MDBNavbar
          fixed="top"
          expand="md"
          transparent
          scrolling
          color="white"
          dark
        >
          <Container>
            <MDBNavbarBrand href="/">YouVape</MDBNavbarBrand>
            <a
              className="sidenav-toggle"
              href="#!"
              onClick={this.handleToggleClickA}
            >
              <Fa icon="bars" size="lg" />
            </a>
            <Collapse isOpen={this.state.collapse} navbar>
              <NavbarItem />
            </Collapse>
          </Container>
        </MDBNavbar>

        <MDBSideNav hidden triggerOpening={isLeftOpen} className="main-nav">
          <MDBNavbarBrand href="/">YouVape</MDBNavbarBrand>
          <NavbarItem />
        </MDBSideNav>
      </div>
    );
  }
}
export default Topnav;
