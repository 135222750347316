import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  MDBTabPane,
  MDBTabContent,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  Fa,
  MDBContainer,
  MDBInput,
  MDBSelect
} from "mdbreact";
import classnames from "classnames";
import Results from "./Results";
import SymptomCough from "./SymptomCough";
import SymptomShortBreath from "./SymptomShortBreath";
import SymptomChestPain from "./SymptomChestPain";
import SymptomCoughingBlood from "./SymptomCoughingBlood";
import SymptomNausea from "./SymptomNausea";
import SymptomVomiting from "./SymptomVomiting";
import SymptomDiarrhea from "./SymptomDiarrhea";
import SymptomAbdominalPain from "./SymptomAbdominalPain";
import SymptomFever from "./SymptomFever";
import SymptomChills from "./SymptomChills";
import SymptomFatigue from "./SymptomFatigue";
import SymptomWeightLoss from "./SymptomWeightLoss";
import * as GuidanceActions from "../actions/guidance-actions";

import GoogleAnalyticsCode from "./GoogleAnalyticsCode";

import ReactGA from "react-ga";

// GOOGLE ANALYTICS

if (process.env.REACT_APP_NODE_ENV == "test") {
  ReactGA.initialize("UA-150281148-1");
} else if (process.env.REACT_APP_NODE_ENV == "prod") {
  ReactGA.initialize("UA-150281148-2");
}

ReactGA.pageview(window.location.pathname + window.location.search);

//Checkbox labels - Edit here if there are any changes to the labels
const PRODUCT_CHECKBOX_OPTIONS = [
  "Convenience store or Gas station",
  "Pharmacy",
  "Online",
  "Family or friend",
  "Other locations"
];
const PRODUCT_TYPES_CHECKBOX_OPTIONS = [
  "Nicotine",
  "THC",
  "CBD",
  "Flavored",
  "Homemade",
  "Other products"
];
const PRODUCT_VAPING_BRANDS = [
  "Blu",
  "Brass Knuckles",
  "Cereal Carts",
  "Dank Vape",
  "Exotics",
  "Juul",
  "Kingpen",
  "Mario Carts",
  "Mig 21",
  "Pax",
  "Stiiizy",
  "TKO",
  "Other"
];

class ProgressTabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [
        {
          text: "Option 1",
          value: "1"
        },
        {
          text: "Option 2",
          value: "2"
        },
        {
          text: "Option 3",
          value: "3"
        }
      ],
      activeItemPills: "1",
      completeItemPills: "",
      radio: "",
      when_did_you_start_vaping: "",
      when_did_you_last_vape: "",
      typical_vaping_per_day: "",
      when_did_your_symptoms_start: "",
      have_you_seen_doctor: "",
      age: "",
      sex: "",
      chronic_condition_flag: "",
      chronic_condition_desc: "",
      disable_chronic_condition_desc: true,
      disable_unusual_smell_flag: true,
      displaySymptoms: true,
      items: {
        default: "1"
      },
      allSymptoms: [
        { id: "cough", value: <SymptomCough /> },
        { id: "difficulty breathing", value: <SymptomShortBreath /> },
        { id: "chest pain", value: <SymptomChestPain /> },
        { id: "coughing up blood", value: <SymptomCoughingBlood /> },
        { id: "nausea", value: <SymptomNausea /> },
        { id: "vomiting", value: <SymptomVomiting /> },
        { id: "diarrhea", value: <SymptomDiarrhea /> },
        { id: "stomach pain", value: <SymptomAbdominalPain /> },
        { id: "fever", value: <SymptomFever /> },
        { id: "chills", value: <SymptomChills /> },
        { id: "feeling tired", value: <SymptomFatigue /> },
        { id: "weight loss", value: <SymptomWeightLoss /> }
      ],
      selectedSymptoms: {},
      vaping_products: [],
      vaping_product_usage: [],
      vaping_product_other_text: "",
      vaping_product_usage_other_text: "",
      vaping_product_brands_other_text: "",

      vaping_product_other_flag: false,
      vaping_product_usage_other_flag: false,
      vaping_product_brands_other_flag: false,
      vaping_products_homemade_flag: false,
      vaping_products_flavor_flag: false,

      vaping_product_brands: [],
      vaping_product_flavor: "",
      vaping_product_ingredients: "",
      vaping_product_unusual_flag: "",
      vaping_product_unusual_desc: "",

      disable_contact_consent_flag: true,
      participant_zipcode: "",
      participant_email: "",
      consentFlag: "",
      additionalComments: "",

      checkboxes: PRODUCT_CHECKBOX_OPTIONS.reduce(
        (options, option) => ({
          ...options,
          [option]: false
        }),
        {}
      ),
      checkboxes: PRODUCT_TYPES_CHECKBOX_OPTIONS.reduce(
        (options, option) => ({
          ...options,
          [option]: false
        }),
        {}
      ),
      checkboxes: PRODUCT_VAPING_BRANDS.reduce(
        (options, option) => ({
          ...options,
          [option]: false
        }),
        {}
      )
    };
    this.selectSymptomItem.bind(this);
    this.setQst1_historyOptions = this.setQst1_historyOptions.bind(this);
    this.setQst2_historyOptions = this.setQst2_historyOptions.bind(this);
    this.setQst3_historyOptions = this.setQst3_historyOptions.bind(this);

    this.setsymptomDetailsOptions_q1 = this.setsymptomDetailsOptions_q1.bind(
      this
    );
    this.setsymptomDetailsOptions_q2 = this.setsymptomDetailsOptions_q2.bind(
      this
    );

    this.setPersonalDetails_q1 = this.setPersonalDetails_q1.bind(this);
    this.setPersonalDetails_q2 = this.setPersonalDetails_q2.bind(this);
    this.setPersonalDetails_q3 = this.setPersonalDetails_q3.bind(this);
    this.setPersonalDetails_q4 = this.setPersonalDetails_q4.bind(this);

    this.set_VapingFlavor = this.set_VapingFlavor.bind(this);

    this.renderItem = this.renderItem.bind(this);
    this.togglePills = this.togglePills.bind(this);
  }

  /* ********************************************************
              PRODUCT DETAILS
  *************************************************************/

  createProductCheckboxes = () =>
    PRODUCT_CHECKBOX_OPTIONS.map(this.createProductCheckbox);
  createProductUsageCheckboxes = () =>
    PRODUCT_TYPES_CHECKBOX_OPTIONS.map(this.createProductUsageCheckbox);
  createVapingBrandsCheckboxes = () =>
    PRODUCT_VAPING_BRANDS.map(this.createVapingBrandsCheckbox);

  handleCheckboxChange = changeEvent => {
    const { name } = changeEvent.target;
    this.setState(prevState => ({
      checkboxes: {
        ...prevState.checkboxes,
        [name]: !prevState.checkboxes[name]
      }
    }));
  };

  createProductCheckbox = option => (
    <MDBInput
      label={option}
      isSelected={this.state.checkboxes[option]}
      // onCheckboxChange={this.handleCheckboxChange}
      onClick={this.setProductCheckbox}
      key={option}
      type="checkbox"
      id={option}
      value={option.replace(/\ /g, "_")}
    />
  );

  createProductUsageCheckbox = option => (
    <MDBInput
      label={option}
      isSelected={this.state.checkboxes[option]}
      // onCheckboxChange={this.handleCheckboxChange}
      onClick={this.setProductUsageCheckbox}
      key={option}
      type="checkbox"
      id={option}
      value={option.replace(/\ /g, "_")}
    />
  );

  createVapingBrandsCheckbox = option => (
    <MDBInput
      label={option}
      isSelected={this.state.checkboxes[option]}
      // onCheckboxChange={this.handleCheckboxChange}
      onClick={this.set_VapingBrands}
      key={option}
      type="checkbox"
      id={option}
      value={option.replace(/\ /g, "_")}
    />
  );

  setProductCheckbox = e => {
    var checked = e.target.checked;

    if (checked) {
      this.setState(
        {
          vaping_products: [...this.state.vaping_products, e.target.value]
        },
        () => {
          this.props.setVapingProductsBroughtFrom(this.state.vaping_products);
        }
      );
    } else {
      var copyOfArray = [...this.state.vaping_products]; // make a separate copy of the array
      var index = copyOfArray.indexOf(e.target.value);
      if (index !== -1) {
        copyOfArray.splice(index, 1);
        this.setState({ vaping_products: copyOfArray }, () => {
          this.props.setVapingProductsBroughtFrom(this.state.vaping_products);
        });
      }
    }

    if (e.target.value == "Other_locations") {
      this.setState(prevState => ({
        vaping_product_other_flag: !prevState.vaping_product_other_flag
      }));
    }
  };

  setProductUsageCheckbox = e => {
    var checked = e.target.checked;

    if (checked) {
      this.setState(
        {
          vaping_product_usage: [
            ...this.state.vaping_product_usage,
            e.target.value
          ]
        },
        () => {
          this.props.setVapingProductsUsed(this.state.vaping_product_usage);
        }
      );
    } else {
      var copyOfArray = [...this.state.vaping_product_usage]; // make a separate copy of the array
      var index = copyOfArray.indexOf(e.target.value);
      if (index !== -1) {
        copyOfArray.splice(index, 1);
        this.setState({ vaping_product_usage: copyOfArray }, () => {
          this.props.setVapingProductsUsed(this.state.vaping_product_usage);
        });
      }
    }

    if (e.target.value == "Other_products") {
      this.setState(prevState => ({
        vaping_product_usage_other_flag: !prevState.vaping_product_usage_other_flag
      }));
    }
    // Flavor Flag set
    if (e.target.value == "Flavored") {
      this.setState(prevState => ({
        vaping_products_flavor_flag: !prevState.vaping_products_flavor_flag
      }));
    }
    // Homemade Flag set
    if (e.target.value == "Homemade") {
      this.setState(prevState => ({
        vaping_products_homemade_flag: !prevState.vaping_products_homemade_flag
      }));
    }
  };

  set_VapingBrands = e => {
    var checked = e.target.checked;
    if (checked) {
      this.setState(
        {
          vaping_product_brands: [
            ...this.state.vaping_product_brands,
            e.target.value
          ]
        },
        () => {
          this.props.setVapingBrands(this.state.vaping_product_brands);
        }
      );
    } else {
      var copyOfArray = [...this.state.vaping_product_brands]; // make a separate copy of the array
      var index = copyOfArray.indexOf(e.target.value);
      if (index !== -1) {
        copyOfArray.splice(index, 1);
        this.setState({ vaping_product_brands: copyOfArray }, () => {
          this.props.setVapingBrands(this.state.vaping_product_brands);
        });
      }
    }

    if (e.target.value == "Other") {
      this.setState(prevState => ({
        vaping_product_brands_other_flag: !prevState.vaping_product_brands_other_flag
      }));
    }
  };

  setProductOtherOption = e => {
    this.setState({
      vaping_product_other_text: e.target.value
    });
    this.props.setProductOtherText(e.target.value);
  };

  setProductUsageOtherOption = e => {
    this.setState({
      vaping_product_usage_other_text: e.target.value
    });
    this.props.setProductUsageOtherText(e.target.value);
  };

  setVapingBrandOtherOption = e => {
    this.setState({
      vaping_product_brands_other_text: e.target.value
    });
    this.props.setVapingBrandOtherText(e.target.value);
  };

  set_VapingFlavor = e => {
    this.setState({
      vaping_product_flavor: e.target.value
    });
    this.props.setVapingFlavor(e.target.value);
  };

  set_VapingIngredients = e => {
    this.setState({
      vaping_product_ingredients: e.target.value
    });
    this.props.setVapingIngredients(e.target.value);
  };

  set_UnusualCharactersFlag = e => {
    this.setState({
      vaping_product_unusual_flag: e.target.value
    });

    if (
      e.target.value == "no" &&
      this.state.disable_unusual_smell_flag == true
    ) {
    } else {
      this.setState(prevState => ({
        disable_unusual_smell_flag: !prevState.disable_unusual_smell_flag
      }));
    }

    this.props.setUnusualCharactersFlag(e.target.value);
  };
  set_UnusualCharactersDesc = e => {
    this.setState({
      vaping_product_unusual_desc: e.target.value
    });
    this.props.setUnusualCharactersDesc(e.target.value);
  };

  /* ********************************************************
              PARTICIPANT Additional Details
  *************************************************************/

  set_zipcode = e => {
    this.setState({
      participant_zipcode: e.target.value
    });
    this.props.setZipcode(e.target.value);
  };

  set_email = e => {
    this.setState({
      participant_email: e.target.value
    });
    this.props.setEmail(e.target.value);
  };

  set_contactForMoreInfoFlag = e => {
    this.setState({
      consentFlag: e.target.value
    });

    if (
      e.target.value == "no" &&
      this.state.disable_contact_consent_flag == true
    ) {
    } else {
      this.setState(prevState => ({
        disable_contact_consent_flag: !prevState.disable_contact_consent_flag
      }));
    }

    this.props.setContactConsentFlag(e.target.value);
  };

  set_additionalComments = e => {
    this.setState({
      additionalComments: e.target.value
    });
    this.props.setAdditionalComments(e.target.value);
  };

  /* ********************************************************
              PARTICIPANT HISTORY OPTIONS
  *************************************************************/
  setQst1_historyOptions = e => {
    this.setState({
      when_did_you_start_vaping: e.target.value
    });
    this.props.setWhenVapedOption(e.target.value);
  };

  setQst2_historyOptions = e => {
    this.setState({
      typical_vaping_per_day: e.target.value
    });
    this.props.setTypicalVapingPerDayOption(e.target.value);
  };

  setQst3_historyOptions = e => {
    this.setState({
      when_did_you_last_vape: e.target.value
    });
    this.props.setLastVapedOption(e.target.value);
  };

  /* ********************************************************
              PARTICIPANT SYMPTOM DETAILS
  *************************************************************/

  setsymptomDetailsOptions_q1 = e => {
    this.setState({
      when_did_your_symptoms_start: e.target.value
    });
    this.props.setSymptomDetailOption(e.target.value);
  };

  setsymptomDetailsOptions_q2 = e => {
    this.setState({
      have_you_seen_doctor: e.target.value
    });
    this.props.setConsultedDoctorOption(e.target.value);
  };

  selectSymptomItem(el) {
    // console.log(el)
    var selected = this.state.selectedSymptoms;
    selected[el] = !selected[el];
    this.setState({ selectedSymptoms: selected });
    if (selected[el]) {
      // console.log('symtom selected')
      this.props.selectSymptom(el);
    } else {
      // console.log('symtom unselected')
      this.props.unselectSymptom(el);
    }
  }

  renderItem(el) {
    var className = this.state.selectedSymptoms[el.id]
      ? "selected-symptom"
      : "symptom-group-wrapper";
    var onClick = this.selectSymptomItem.bind(this, el.id);
    return (
      <span key={el.id} className={className} onClick={onClick}>
        {el.value}
      </span>
    );
  }

  /* ********************************************************
              PARTICIPANT PERSONAL DETAILS
  *************************************************************/
  setPersonalDetails_q1 = e => {
    console.log("Value Selcted -> ", e.target.value);
    this.setState({
      age: e.target.value
    });
    this.props.setParticipantAge(e.target.value);
  };
  setPersonalDetails_q2 = e => {
    this.setState({
      sex: e.target.value
    });
    this.props.setParticipantSex(e.target.value);
  };

  setPersonalDetails_q3 = e => {
    this.setState({
      chronic_condition_flag: e.target.value
    });

    // Toggle Chronic condition text input field
    // Set to true by default, so when clicking on "no" on launch, we should not display the field
    if (
      e.target.value == "no" &&
      this.state.disable_chronic_condition_desc == true
    ) {
    } else {
      this.setState(prevState => ({
        disable_chronic_condition_desc: !prevState.disable_chronic_condition_desc
      }));
    }

    this.props.setChronicConditionFlag(e.target.value);
  };
  setPersonalDetails_q4 = e => {
    this.setState({
      chronic_condition_desc: e.target.value
    });
    this.props.setChronicConditionDesc(e.target.value);
  };

  // togglePills = (type, tab) => e => {
  //   e.preventDefault();
  //   if (this.state.items[type] !== tab) {
  //     let items = { ...this.state.items };
  //     items[type] = tab;
  //     this.setState({
  //       items
  //     });
  //   }
  // };

  togglePills(tab) {
    if (this.state.activePills !== tab) {
      this.setState({
        activeItemPills: tab
      });
    }
  }

  render() {
    return (
      <div className="progress-tabs">
        <MDBNav pills className="nav-justified">
          <MDBNavItem>
            <div
              className={classnames({
                "nav-link": true,
                "Ripple-parent": true,
                active: this.props.vapingProgressTabIndex === 1,
                complete: this.props.vapingProgressTabIndex >= 2
              })}
            >
              <span className="number">1.</span>
              <span className="hidden-sm-down">History</span>
            </div>
            {/* <MDBNavLink
              to="#"
              className={classnames({
                active: this.props.vapingProgressTabIndex === 1,
                complete: this.props.vapingProgressTabIndex >= 2
              })}>
              <span className="number">1.</span>
              <span className="hidden-sm-down">History</span>
            </MDBNavLink> */}
          </MDBNavItem>
          <MDBNavItem>
            <div
              className={classnames({
                "nav-link": true,
                "Ripple-parent": true,
                active: this.props.vapingProgressTabIndex === 2,
                complete: this.props.vapingProgressTabIndex >= 3
              })}
            >
              <span className="number">2.</span>
              <span className="hidden-sm-down">Symptoms</span>
            </div>
            {/* <MDBNavLink
              to="#"
              className={classnames({
                active: this.props.vapingProgressTabIndex === 2,
                complete: this.props.vapingProgressTabIndex >= 3
              })}
            // active={this.state.items["default"] === "2"}
            // onClick={this.togglePills("default", "2")}
            >
              <span className="number">2.</span>
              <span className="hidden-sm-down">Symptoms</span>
            </MDBNavLink> */}
          </MDBNavItem>
          <MDBNavItem>
            <div
              className={classnames({
                "nav-link": true,
                "Ripple-parent": true,
                active: this.props.vapingProgressTabIndex === 3,
                complete: this.props.vapingProgressTabIndex >= 4
              })}
            >
              <span className="number">3.</span>
              <span className="hidden-sm-down">Your details</span>
            </div>
            {/* <MDBNavLink
              to="/sadf"
              className={classnames({
                active: this.props.vapingProgressTabIndex === 3,
                complete: this.props.vapingProgressTabIndex >= 4
              })}>
              <span className="number">3.</span>
              <span className="hidden-sm-down">Your details</span>
            </MDBNavLink> */}
          </MDBNavItem>
          <MDBNavItem>
            <div
              className={classnames({
                "nav-link": true,
                "Ripple-parent": true,
                active: this.props.vapingProgressTabIndex === 4,
                complete: this.props.vapingProgressTabIndex >= 5
              })}
            >
              <span className="number">4.</span>
              <span className="hidden-sm-down">Product</span>
            </div>
            {/* <MDBNavLink
              to="#"
              className={classnames({
                active: this.props.vapingProgressTabIndex === 4,
                complete: this.props.vapingProgressTabIndex >= 5
              })}>
              <span className="number">4.</span>
              <span className="hidden-sm-down">Product</span>
            </MDBNavLink> */}
          </MDBNavItem>
          <MDBNavItem>
            <div
              className={classnames({
                "nav-link": true,
                "Ripple-parent": true,
                active: this.props.vapingProgressTabIndex === 5
              })}
            >
              <span className="number">5.</span>
              <span className="hidden-sm-down">Additional info</span>
            </div>
            {/* <MDBNavLink
              to="#"
              className={classnames({
                active: this.props.vapingProgressTabIndex === 5,
              })}>
              <span className="number">5.</span>
              <span className="hidden-sm-down">Additional info</span>
            </MDBNavLink> */}
          </MDBNavItem>
        </MDBNav>
        <MDBTabContent activeItem={"" + this.props.vapingProgressTabIndex}>
          <MDBTabPane tabId="1">
            <GoogleAnalyticsCode
              GA_Category="History"
              GA_Action="Participant history details submission"
              GA_Label="History details"
              GA_Value="1"
            />
            <form className="mb-3 text-left">
              <div className="col">
                <MDBContainer className="">
                  <label>
                    When did you start vaping?
                    {this.props.formErrors &&
                      this.props.formErrors.err_started_vaping == true && (
                        <div className="error-occured">
                          {" "}
                          Please select an option{" "}
                        </div>
                      )}
                  </label>
                  <MDBInput
                    name="when_did_you_start_vaping"
                    onClick={this.setQst1_historyOptions}
                    checked={
                      this.state.when_did_you_start_vaping === "this_month"
                        ? true
                        : false
                    }
                    label="This month"
                    type="radio"
                    id="radio1"
                    value="this_month"
                  />
                  <MDBInput
                    name="when_did_you_start_vaping"
                    onClick={this.setQst1_historyOptions}
                    checked={
                      this.state.when_did_you_start_vaping === "1-6_months_ago"
                        ? true
                        : false
                    }
                    label="1&ndash;6 months ago"
                    type="radio"
                    id="radio2"
                    value="1-6_months_ago"
                  />
                  <MDBInput
                    name="when_did_you_start_vaping"
                    onClick={this.setQst1_historyOptions}
                    checked={
                      this.state.when_did_you_start_vaping === "6-12_months_ago"
                        ? true
                        : false
                    }
                    label="6&ndash;12 months ago"
                    type="radio"
                    id="radio3"
                    value="6-12_months_ago"
                  />
                  <MDBInput
                    name="when_did_you_start_vaping"
                    onClick={this.setQst1_historyOptions}
                    checked={
                      this.state.when_did_you_start_vaping === "1_year_ago"
                        ? true
                        : false
                    }
                    label="Over a year ago"
                    type="radio"
                    id="radio4"
                    value="1_year_ago"
                  />
                </MDBContainer>

	    <div className="mt-5">
                  <MDBContainer className="">
                    <label>
                      When did you last vape?
                      {this.props.formErrors &&
                        this.props.formErrors.err_last_vaped == true && (
                          <div className="error-occured">
                            {" "}
                            Please select an option{" "}
                          </div>
                        )}
                    </label>
                    <MDBInput
                      name="when_did_you_last_vape"
                      onClick={this.setQst3_historyOptions}
                      checked={
                        this.state.when_did_you_last_vape === "this_week"
                          ? true
                          : false
                      }
                      label="This week"
                      type="radio"
                      id="radio9"
                      value="this_week"
                    />
                    <MDBInput
                      name="when_did_you_last_vape"
                      onClick={this.setQst3_historyOptions}
                      checked={
                        this.state.when_did_you_last_vape === "1-4_weeks_ago"
                          ? true
                          : false
                      }
                      label="1&ndash;4 weeks ago"
                      type="radio"
                      id="radio10"
                      value="1-4_weeks_ago"
                    />
                    <MDBInput
                      name="when_did_you_last_vape"
                      onClick={this.setQst3_historyOptions}
                      checked={
                        this.state.when_did_you_last_vape === "1-12_months_ago"
                          ? true
                          : false
                      }
                      label="1&ndash;12 months ago"
                      type="radio"
                      id="radio11"
                      value="1-12_months_ago"
                    />
                    <MDBInput
                      name="when_did_you_last_vape"
                      onClick={this.setQst3_historyOptions}
                      checked={
                        this.state.when_did_you_last_vape === "over_year_ago"
                          ? true
                          : false
                      }
                      label="Over a year ago"
                      type="radio"
                      id="radio12"
                      value="over_year_ago"
                    />
                  </MDBContainer>
                </div>



                <div className="mt-5">
                  <MDBContainer className="">
                    <label>
                      In the past three months, how many times did you vape on a
                      typical day?
                      {this.props.formErrors &&
                        this.props.formErrors.err_typical_day_vaping ==
                        true && (
                          <div className="error-occured">
                            {" "}
                            Please select an option{" "}
                          </div>
                        )}
                    </label>
                    <MDBInput
                      name="typical_vaping_per_day"
                      onClick={this.setQst2_historyOptions}
                      checked={
                        this.state.typical_vaping_per_day ===
                          "less_than_once_per_day"
                          ? true
                          : false
                      }
                      label="Less than once a day"
                      type="radio"
                      id="radio5"
                      value="less_than_once_per_day"
                    />
                    <MDBInput
                      name="typical_vaping_per_day"
                      onClick={this.setQst2_historyOptions}
                      checked={
                        this.state.typical_vaping_per_day === "once_a_day"
                          ? true
                          : false
                      }
                      label="Once a day"
                      type="radio"
                      id="radio6"
                      value="once_a_day"
                    />
                    <MDBInput
                      name="typical_vaping_per_day"
                      onClick={this.setQst2_historyOptions}
                      checked={
                        this.state.typical_vaping_per_day ===
                          "2-3_times_per_day"
                          ? true
                          : false
                      }
                      label="2&ndash;3 times a day"
                      type="radio"
                      id="radio7"
                      value="2-3_times_per_day"
                    />
                    <MDBInput
                      name="typical_vaping_per_day"
                      onClick={this.setQst2_historyOptions}
                      checked={
                        this.state.typical_vaping_per_day ===
                          "3_or_more_times_per_day"
                          ? true
                          : false
                      }
                      label="3 or more times a day"
                      type="radio"
                      id="radio8"
                      value="3_or_more_times_per_day"
                    />
                  </MDBContainer>
                </div>
              </div>
            </form>
          </MDBTabPane>

          {(this.props.symptomDetails &&
            this.props.symptomDetails.symptoms &&
            this.props.symptomDetails.symptoms.length == 0) ||
            (this.props.symptomsDisplayFlag &&
              this.props.symptomsDisplayFlag == true) ? (
              <MDBTabPane tabId="2">
                <GoogleAnalyticsCode
                  GA_Category="Symptoms"
                  GA_Action="Participant Symptom details submission"
                  GA_Label="Symptoms"
                  GA_Value="2"
                />
                <div className="col">
                  {/* <h4 className="mb-3"> */}
                  <label>
                    Have you noticed a change in how you are feeling since you
                  started vaping?
                  <br />
                    Select all symptoms you have that you think might have been
                  caused by vaping.
                  {this.props.formErrors &&
                      this.props.formErrors.err_selecting_symptoms == true && (
                        <div className="error-occured">
                          {" "}
                          Please select at least one symptom{" "}
                        </div>
                      )}
                  </label>
                  {/* </h4> */}
                </div>
                <div className="d-flex justify-content-center flex-wrap col-lg-9 ml-auto mr-auto">
                  {this.state.allSymptoms.map(this.renderItem)}
                </div>
              </MDBTabPane>
            ) : (
              <MDBTabPane tabId="2">
                <GoogleAnalyticsCode
                  GA_Category="Symptoms"
                  GA_Action="Participant Symptom options"
                  GA_Label="Symptoms options"
                  GA_Value="2"
                />
                <form className="mt-3 mb-3 text-left">
                  <MDBContainer className="">
                    <label>
                      When did your symptoms start?
                    {this.props.formErrors &&
                        this.props.formErrors
                          .err_selecting_symptoms_started_time == true && (
                          <div className="error-occured">
                            {" "}
                            Please select an option{" "}
                          </div>
                        )}
                    </label>
                    <MDBInput
                      name="symptoms_started_timeframe"
                      onClick={this.setsymptomDetailsOptions_q1}
                      checked={
                        this.state.when_did_your_symptoms_start === "today"
                          ? true
                          : false
                      }
                      label="Today"
                      type="radio"
                      id="symptoms-start-1"
                      value="today"
                    />
                    <MDBInput
                      name="symptoms_started_timeframe"
                      onClick={this.setsymptomDetailsOptions_q1}
                      checked={
                        this.state.when_did_your_symptoms_start === "this_week"
                          ? true
                          : false
                      }
                      label="This week"
                      type="radio"
                      id="symptoms-start-2"
                      value="this_week"
                    />
                    <MDBInput
                      name="symptoms_started_timeframe"
                      onClick={this.setsymptomDetailsOptions_q1}
                      checked={
                        this.state.when_did_your_symptoms_start ===
                          "over_a_week_ago"
                          ? true
                          : false
                      }
                      label="Over a week ago"
                      type="radio"
                      id="symptoms-start-3"
                      value="over_a_week_ago"
                    />
                    <MDBInput
                      name="symptoms_started_timeframe"
                      onClick={this.setsymptomDetailsOptions_q1}
                      checked={
                        this.state.when_did_your_symptoms_start ===
                          "over_a_month_ago"
                          ? true
                          : false
                      }
                      label="Over a month ago"
                      type="radio"
                      id="symptoms-start-4"
                      value="over_a_month_ago"
                    />
                    <MDBInput
                      name="symptoms_started_timeframe"
                      onClick={this.setsymptomDetailsOptions_q1}
                      checked={
                        this.state.when_did_your_symptoms_start ===
                          "over_a_year_ago"
                          ? true
                          : false
                      }
                      label="Over a year ago"
                      type="radio"
                      id="symptoms-start-5"
                      value="over_a_year_ago"
                    />
                  </MDBContainer>

                  <MDBContainer className="mt-5">
                    <label>Have you seen a doctor for these symptoms?</label>
                    <MDBInput
                      name="haveSeenDoctorQst"
                      onClick={this.setsymptomDetailsOptions_q2}
                      checked={
                        this.state.have_you_seen_doctor === "yes" ? true : false
                      }
                      label="Yes"
                      type="radio"
                      id="sought-care-yes"
                      value="yes"
                    />
                    <MDBInput
                      name="haveSeenDoctorQst"
                      onClick={this.setsymptomDetailsOptions_q2}
                      checked={
                        this.state.have_you_seen_doctor === "no" ? true : false
                      }
                      label="No"
                      type="radio"
                      id="sought-care-no"
                      value="no"
                    />
                  </MDBContainer>
                </form>
              </MDBTabPane>
            )}

          {/* {this.props.symptomDetails &&
            this.props.symptomDetails.symptoms &&
            this.props.symptomDetails.symptoms.length > 0 &&
            this.props.symptomsDisplayFlag == false && (
              <MDBTabPane tabId="2">
                <GoogleAnalyticsCode
                  GA_Category="Symptoms"
                  GA_Action="Participant Symptom options"
                  GA_Label="Symptoms options"
                  GA_Value="2"
                />
                <form className="mt-3 mb-3 text-left">
                  <MDBContainer className="">
                    <label>
                      When did your symptoms start?
                      {this.props.formErrors &&
                        this.props.formErrors
                          .err_selecting_symptoms_started_time == true && (
                          <div className="error-occured">
                            {" "}
                            Please select an option{" "}
                          </div>
                        )}
                    </label>
                    <MDBInput
                      name="symptoms_started_timeframe"
                      onClick={this.setsymptomDetailsOptions_q1}
                      checked={
                        this.state.when_did_your_symptoms_start === "today"
                          ? true
                          : false
                      }
                      label="Today"
                      type="radio"
                      id="symptoms-start-1"
                      value="today"
                    />
                    <MDBInput
                      name="symptoms_started_timeframe"
                      onClick={this.setsymptomDetailsOptions_q1}
                      checked={
                        this.state.when_did_your_symptoms_start === "this_week"
                          ? true
                          : false
                      }
                      label="This week"
                      type="radio"
                      id="symptoms-start-2"
                      value="this_week"
                    />
                    <MDBInput
                      name="symptoms_started_timeframe"
                      onClick={this.setsymptomDetailsOptions_q1}
                      checked={
                        this.state.when_did_your_symptoms_start ===
                          "over_a_week_ago"
                          ? true
                          : false
                      }
                      label="Over a week ago"
                      type="radio"
                      id="symptoms-start-3"
                      value="over_a_week_ago"
                    />
                    <MDBInput
                      name="symptoms_started_timeframe"
                      onClick={this.setsymptomDetailsOptions_q1}
                      checked={
                        this.state.when_did_your_symptoms_start ===
                          "over_a_month_ago"
                          ? true
                          : false
                      }
                      label="Over a month ago"
                      type="radio"
                      id="symptoms-start-4"
                      value="over_a_month_ago"
                    />
                    <MDBInput
                      name="symptoms_started_timeframe"
                      onClick={this.setsymptomDetailsOptions_q1}
                      checked={
                        this.state.when_did_your_symptoms_start ===
                          "over_a_year_ago"
                          ? true
                          : false
                      }
                      label="Over a year ago"
                      type="radio"
                      id="symptoms-start-5"
                      value="over_a_year_ago"
                    />
                  </MDBContainer>

                  <MDBContainer className="mt-5">
                    <label>Have you seen a doctor for these symptoms?</label>
                    <MDBInput
                      name="haveSeenDoctorQst"
                      onClick={this.setsymptomDetailsOptions_q2}
                      checked={
                        this.state.have_you_seen_doctor === "yes" ? true : false
                      }
                      label="Yes"
                      type="radio"
                      id="sought-care-yes"
                      value="yes"
                    />
                    <MDBInput
                      name="haveSeenDoctorQst"
                      onClick={this.setsymptomDetailsOptions_q2}
                      checked={
                        this.state.have_you_seen_doctor === "no" ? true : false
                      }
                      label="No"
                      type="radio"
                      id="sought-care-no"
                      value="no"
                    />
                  </MDBContainer>
                </form>
              </MDBTabPane>
            )} */}

          <MDBTabPane tabId="3">
            <GoogleAnalyticsCode
              GA_Category="Participant Details"
              GA_Action="Participant details submission"
              GA_Label="Your Details"
              GA_Value="3"
            />
            <div className="text-left row no-gutters">
              <form>
                <div className="col">
                  <MDBContainer>
                    <label>
                      How old are you?
                      {/* {this.props.formErrors &&
                      this.props.formErrors.err_age_format == true && (
                        <div className="error-occured">
                          {" "}
                          Please enter numeric values only {" "}
                        </div>
                      )} */}
                    </label>

                    <MDBInput
                      name="participant_age"
                      onClick={this.setPersonalDetails_q1}
                      checked={this.state.age === "under_18" ? true : false}
                      label="Under 18"
                      type="radio"
                      id="age-1"
                      value="under_18"
                    />
                    <MDBInput
                      name="participant_age"
                      onClick={this.setPersonalDetails_q1}
                      checked={this.state.age === "18-24" ? true : false}
                      label="18&ndash;24"
                      type="radio"
                      id="age-2"
                      value="18-24"
                    />
                    <MDBInput
                      name="participant_age"
                      onClick={this.setPersonalDetails_q1}
                      checked={this.state.age === "24-35" ? true : false}
                      label="24&ndash;35"
                      type="radio"
                      id="age-3"
                      value="24-35"
                    />
                    <MDBInput
                      name="participant_age"
                      onClick={this.setPersonalDetails_q1}
                      checked={this.state.age === "35-45" ? true : false}
                      label="35&ndash;45"
                      type="radio"
                      id="age-4"
                      value="35-45"
                    />
                    <MDBInput
                      name="participant_age"
                      onClick={this.setPersonalDetails_q1}
                      checked={this.state.age === "45-54" ? true : false}
                      label="45&ndash;54"
                      type="radio"
                      id="age-5"
                      value="45-54"
                    />
                    <MDBInput
                      name="participant_age"
                      onClick={this.setPersonalDetails_q1}
                      checked={this.state.age === "55_and_above" ? true : false}
                      label="55 and above"
                      type="radio"
                      id="age-6"
                      value="55_and_above"
                    />
                  </MDBContainer>
                  {/* <div>
                    <select className="browser-default custom-select form-control form-control input-rounded input-white" onChange={this.setPersonalDetails_q1}>
                      <option>Select an option</option>
                      <option value="under_18">Under 18</option>
                      <option value="18-24">18-24</option>
                      <option value="24-35">24-35</option>
                      <option value="35-45">35-45</option>
                      <option value="45-54">45-54</option>
                      <option value="55_and_above">55 and above</option>
                    </select>
                  </div> */}
                  {/* <input
                    type="text"
                    id="participant_age_id1"
                    className="form-control form-control-small input-rounded input-white"
                    onChange={this.setPersonalDetails_q1}
                    name="participantAge"
                  /> */}
                  <MDBContainer className="mt-5">
                    <label>What is your biological sex?</label>
                    <MDBInput
                      name="participant_sex"
                      onClick={this.setPersonalDetails_q2}
                      checked={this.state.sex === "male" ? true : false}
                      label="Male"
                      type="radio"
                      id="radio_sex_1"
                      value="male"
                    />
                    <MDBInput
                      name="participant_sex"
                      onClick={this.setPersonalDetails_q2}
                      checked={this.state.sex === "female" ? true : false}
                      label="Female"
                      type="radio"
                      id="radio_sex_2"
                      value="female"
                    />
                  </MDBContainer>
                  <MDBContainer className="mt-5">
                    <label>
                      Did you have a chronic medical condition before you
                      started vaping?
                    </label>
                    <MDBInput
                      name="chronicCondition_flag"
                      onClick={this.setPersonalDetails_q3}
                      checked={
                        this.state.chronic_condition_flag === "yes"
                          ? true
                          : false
                      }
                      label="Yes"
                      type="radio"
                      id="chronic-condition-yes"
                      value="yes"
                    />
                    <MDBInput
                      name="chronicCondition_flag"
                      onClick={this.setPersonalDetails_q3}
                      checked={
                        this.state.chronic_condition_flag === "no"
                          ? true
                          : false
                      }
                      label="No"
                      type="radio"
                      id="chronic-condition-no"
                      value="no"
                    />
                  </MDBContainer>

                  {this.state.disable_chronic_condition_desc ? null : (
                    <MDBContainer className="mt-3">
                      <label className="small">
                        Please describe your chronic medical condition.
                      </label>
                      <input
                        type="text"
                        id="chronic_cond_desc"
                        className="form-control input-rounded input-white"
                        onChange={this.setPersonalDetails_q4}
                        name="chronicConditionDesc"
                      />
                    </MDBContainer>
                  )}
                </div>
              </form>
            </div>
          </MDBTabPane>
          <MDBTabPane tabId="4">
            <GoogleAnalyticsCode
              GA_Category="Product"
              GA_Action="Vaping product details submission"
              GA_Label="Vaping Product"
              GA_Value="4"
            />
            <div className="text-left row no-gutters">
              <form>
                <div className="col">
                  <MDBContainer className="">
                    <label>Where do you get your vaping products?</label>
                    {this.createProductCheckboxes()}
                    {/* <MDBInput type="text" name="vape_product_other" id="vapeProduct_id" onChange={this.setOtherVapingProductText} disable={this.vaping_product_other_flag} /> */}
                    {this.state.vaping_product_other_flag ? (
                      <div className="mt-3">
                        <label className="small">
                          Please list all other locations you have bought vaping
                          products from. Use a comma to separate locations.
                        </label>
                        <input
                          name="vape_product_other"
                          onChange={this.setProductOtherOption}
                          type="text"
                          id="other_product"
                          className="form-control"
                        />
                      </div>
                    ) : null}
                    {/* <MDBInput
                      label="Convenience store"
                      type="checkbox"
                      id="checkbox1"
                    />
                    <MDBInput label="Pharmacy" type="checkbox" id="checkbox2" />
                    <MDBInput label="Online" type="checkbox" id="checkbox3" />
                    <MDBInput
                      label="From a friend"
                      type="checkbox"
                      id="checkbox4"
                    />
                    <MDBInput label="Other" type="checkbox" id="checkbox5" /> */}
                  </MDBContainer>
                  <MDBContainer className="mt-5">
                    <label>
                      What vaping products have you used?
                      {this.props.formErrors &&
                        this.props.formErrors.err_selecting_vaping_products ==
                        true && (
                          <div className="error-occured">
                            {" "}
                            Please select at least one product{" "}
                          </div>
                        )}
                    </label>
                    {this.createProductUsageCheckboxes()}
                    {this.state.vaping_product_usage_other_flag ? (
                      <div className="mt-3">
                        <label className="small">
                          Please list all other products you have used. Use a
                          comma to separate products.
                        </label>
                        <input
                          name="vape_product_usage_other"
                          type="text"
                          onChange={this.setProductUsageOtherOption}
                          id="other_usage"
                          className="form-control"
                        />
                      </div>
                    ) : null}
                    {/* <MDBInput label="Nicotine" type="checkbox" id="checkbox6" />
                    <MDBInput label="THC" type="checkbox" id="checkbox7" />
                    <MDBInput label="CBD" type="checkbox" id="checkbox8" />
                    <MDBInput label="Flavored" type="checkbox" id="checkbox9" />
                    <MDBInput
                      label="Homemade"
                      type="checkbox"
                      id="checkbox10"
                    />
                    <MDBInput label="Other" type="checkbox" id="checkbox11" /> */}
                  </MDBContainer>

                  {this.state.vaping_products_flavor_flag ? (
                    <MDBContainer className="mt-3">
                      <label className="small">
                        Please list the flavors you have used. Use a comma to
                        separate flavors.
                      </label>
                      <input
                        type="text"
                        name="vaping_flavor"
                        id="vape_brand_flavor"
                        onChange={this.set_VapingFlavor}
                        className="form-control input-rounded"
                      />
                    </MDBContainer>
                  ) : null}

                  {this.state.vaping_products_homemade_flag ? (
                    <MDBContainer className="mt-3">
                      <label className="small">
                        Please list the ingredients in the homemade product. Use
                        a comma to separate ingredients.
                      </label>
                      <input
                        type="text"
                        name="vaping_Ingredients"
                        id="vape_ingredients"
                        onChange={this.set_VapingIngredients}
                        className="form-control input-rounded"
                      />
                    </MDBContainer>
                  ) : null}

                  <MDBContainer className="mt-5">
                    <label>What vape brands do you use?</label>
                    {/* <input type="text" name="vaping_brands" id="vape_brands_id" onChange={this.set_VapingBrands} className="form-control input-rounded" placeholder="enter vaping brand(s) used" /> */}
                    {this.createVapingBrandsCheckboxes()}
                    {this.state.vaping_product_brands_other_flag ? (
                      <div className="mt-3">
                        <label className="small">
                          Please list all other vape brands you have used. Use a
                          comma to separate brands.
                        </label>
                        <input
                          name="vape_product__brands_other"
                          type="text"
                          onChange={this.setVapingBrandOtherOption}
                          id="other_usage"
                          className="form-control"
                        />
                      </div>
                    ) : null}
                  </MDBContainer>

                  <MDBContainer className="mt-5">
                    <label>
                      Have you noticed anything unusual about your vaping
                      product?
                    </label>
                    <MDBInput
                      label="Yes"
                      type="radio"
                      id="checkbox12"
                      name="unusual_smell_flag"
                      checked={
                        this.state.vaping_product_unusual_flag === "yes"
                          ? true
                          : false
                      }
                      value="yes"
                      onClick={this.set_UnusualCharactersFlag}
                    />
                    <MDBInput
                      label="No"
                      type="radio"
                      id="checkbox13"
                      name="unusual_smell_flag"
                      checked={
                        this.state.vaping_product_unusual_flag === "no"
                          ? true
                          : false
                      }
                      value="no"
                      onClick={this.set_UnusualCharactersFlag}
                    />
                  </MDBContainer>

                  {this.state.disable_unusual_smell_flag ? null : (
                    <MDBContainer className="mt-3">
                      <label className="small">
                        Please describe what was unusual about your vaping
                        product.
                      </label>
                      <input
                        type="text"
                        id=""
                        onChange={this.set_UnusualCharactersDesc}
                        className="form-control input-rounded"
                      />
                    </MDBContainer>
                  )}
                </div>
              </form>
            </div>
          </MDBTabPane>
          <MDBTabPane tabId="5">
            <GoogleAnalyticsCode
              GA_Category="Participant personal details"
              GA_Action="Participant personal details submission"
              GA_Label="GeoLocation etc"
              GA_Value="5"
            />
            <div className="text-left row no-gutters">
              <form>
                <div className="col">
                  <label className="">
                    Please provide your ZIP Code.
                    {this.props.formErrors &&
                      this.props.formErrors.err_zipcode_format == true && (
                        <div className="error-occured">
                          {" "}
                          Please enter a proper format (ex: 12345){" "}
                        </div>
                      )}
                  </label>

                  <input
                    type="text"
                    id=""
                    className="form-control form-control-small input-rounded input-white"
                    onChange={this.set_zipcode}
                  />

                  <MDBContainer className="mt-5">
                    <label>
                      Can we contact you for more information about your
                      experience?
                    </label>
                    <MDBInput
                      onClick={this.set_contactForMoreInfoFlag}
                      checked={this.state.consentFlag === "yes" ? true : false}
                      label="Yes"
                      type="radio"
                      id="contact-yes"
                      value="yes"
                    />
                    <MDBInput
                      onClick={this.set_contactForMoreInfoFlag}
                      checked={this.state.consentFlag === "no" ? true : false}
                      label="No"
                      type="radio"
                      id="contact-no"
                      value="no"
                    />
                  </MDBContainer>

                  {this.state.disable_contact_consent_flag ? null : (
                    <MDBContainer className="mt-3">
                      <label className="small">
                        Please provide your email so we can contact you.
                        {this.props.formErrors &&
                          this.props.formErrors.err_email_format == true && (
                            <div className="error-occured">
                              {" "}
                              Please enter a proper format (ex: me@here.com){" "}
                            </div>
                          )}
                      </label>
                      <input
                        type="text"
                        id=""
                        className="form-control input-rounded input-white"
                        onChange={this.set_email}
                      />
                    </MDBContainer>
                  )}

                  <MDBContainer className="mt-5">
                    <label htmlFor="" className="">
                      Do you have any additional comments?
                    </label>

                    <input
                      type="text"
                      id=""
                      className="form-control input-rounded input-white"
                      onChange={this.set_additionalComments}
                    />
                  </MDBContainer>
                </div>
              </form>
            </div>
          </MDBTabPane>
          {/* <MDBTabPane tabId="6">
            <Results />
          </MDBTabPane> */}
        </MDBTabContent>
      </div>
    );
  }
}

// export default ProgressTabs;

function mapStateToProps(state) {
  //console.log("mapStateToProps: "+JSON.stringify(state));
  return {
    vapingProgressTabIndex: state.vapingSurveyState.vapingProgressTabIndex,
    symptomDetails: state.vapingSurveyState.symptomDetails,
    symptomOptions: state.vapingSurveyState.symptomOptions,
    participantDetails: state.vapingSurveyState.participantDetails,
    referral: state.vapingSurveyState.referral,
    formErrors: state.vapingSurveyState.formErrors,
    symptomsDisplayFlag: state.vapingSurveyState.symptomsDisplayFlag
  };
}

function mapDispatchToProps(dispatch) {
  return {
    guidanceActions: bindActionCreators(GuidanceActions, dispatch)
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProgressTabs);
