import React from "react";
import shortofbreath from "../assets/img/symptoms/difficulty-breathing.svg";
import shortofbreathwhite from "../assets/img/symptoms/short-of-breath-white.svg";
class SymptomShortBreath extends React.Component {
  render() {
    return (
      <div className="symptom-group d-flex flex-column justify-content-center">
        <img
          src={shortofbreath}
          className="symptom-icon"
          alt="Shortness of Breath"
        />
        <img
          src={shortofbreathwhite}
          className="white-outline symptom-icon"
          alt="Shortness of Breath"
        />
        <h5 className="mt-3 mb-0">Difficulty breathing</h5>
      </div>
    );
  }
}

export default SymptomShortBreath;
