import React from "react";
import headache from "../assets/img/symptoms/abdominal-pain.svg";
import headachewhite from "../assets/img/symptoms/headache-white.svg";

class SymptomAbdominalPain extends React.Component {
  render() {
    return (
      <div className="symptom-group d-flex flex-column justify-content-center">
        <img src={headache} className="symptom-icon" alt="Headache" />
        <img
          src={headachewhite}
          className="white-outline symptom-icon"
          alt="Headache"
        />
        <h5 className="mt-3 mb-0">Stomach pain</h5>
      </div>
    );
  }
}

export default SymptomAbdominalPain;
