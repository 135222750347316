import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Fa, Button, FormInline, Input } from "mdbreact";
import Navbar from "./Navbar";
import Footer from "./Footer";
import ProgressTabs from "./ProgressTabs";
import * as GuidanceActions from "../actions/guidance-actions";
import * as SessionTracker from "../util/session-tracker";
// import { FormattedMessage } from "react-intl";

import validator from 'validator';

import ReactGA from "react-ga";

class Survey extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSymptoms: [],
      email: "",
      zipcode: "",
      displaySymptomsOptionsTab: false,

      whenStartedVapingOption: "",
      typicalVapingPerDayOption: "",
      lastVapedOption: "",

      vapingHistoryDetails: [],
      symptoms_started: "",
      consulted_doctor: "",

      age: "",
      sex: "",
      chronic_condition_desc: "",
      chronic_condition_flag: "",

      vapingProductsBroughtFromList: [],
      vapingProductsList: [],
      vapingBrands: [],
      vapingFlavor: "",
      vapingIngredientsHomemade: "",
      vapingProductsOtherText: "",
      vapingProductUsageOtherText: "",
      vapingBrandsOtherText: "",
      unusualSmellFromProduct_flag: "",
      unusualSmellFromProduct_desc: "",

      zipcode: "",
      email: "",
      contactConsentFlag: "",
      additionalComments: ""

    };
  }

  moveNext = () => {
    window.scrollTo(0, 0);
    // TAB 1
    if (this.props.vapingProgressTabIndex == 1) {

      /************************************************************
       * Commenting out the following based on the decision 
       * to make all the fields optional
       * This can be turned on in future. So, DONOT delete
       *************************************************************/
      // if (this.state.whenStartedVapingOption == undefined) {
      //   const status = true;
      //   this.props.guidanceActions.updateStartedVapingError(status);
      //   return;
      // } else {
      //   const status = false;
      //   this.props.guidanceActions.updateStartedVapingError(status);
      // }

      // if (this.state.typicalVapingPerDayOption == undefined) {
      //   const status = true;
      //   this.props.guidanceActions.updateTypicalDayVapingError(status);
      //   return;
      // } else {
      //   const status = false;
      //   this.props.guidanceActions.updateTypicalDayVapingError(status);
      // }

      // if (this.state.lastVapedOption == undefined) {
      //   const status = true;
      //   this.props.guidanceActions.updateLastVapedError(status);
      //   return;
      // } else {
      //   const status = false;
      //   this.props.guidanceActions.updateLastVapedError(status);
      // }

      /**************************************************************
       *  END VALIDATION
       **************************************************************/
      var historyOptionsToDispath = {
        lastVapedOption: this.state.lastVapedOption,
        typicalVapingPerDayOption: this.state.typicalVapingPerDayOption,
        whenStartedVapingOption: this.state.whenStartedVapingOption
      }

      this.props.guidanceActions.storeHistoryDetails(historyOptionsToDispath);

    }
    // TAB 2
    else if (this.props.vapingProgressTabIndex == 2) {

      /************************************************************
       * Commenting out the following based on the decision 
       * to make all the fields optional
       * This can be turned on in future. So, DONOT delete
       *************************************************************/
      // if (this.state.selectedSymptoms.length == 0) {
      //   const status = true
      //   this.props.guidanceActions.updateSymptomsSelectionError(status);

      //   return;
      // }

      // console.log("State output before NEXT on Symptoms tab -> ", this.state)
      // If symptoms are selected then update symptom details and display options tab
      if (this.state.selectedSymptoms.length > 0 && this.state.displaySymptomsOptionsTab == false) {

        this.props.guidanceActions.storeSymptoms(this.state.selectedSymptoms)

        this.setState(prevState => ({
          displaySymptomsOptionsTab: !prevState.displaySymptomsOptionsTab
        }));

        this.props.guidanceActions.updateSymptomsDisplayFlag(false);

        // console.log("Current Symptoms Display Flag **** NEXT **** ----> ", this.props.symptomsDisplayFlag)

        return;

      } else {

        /************************************************************
       * Commenting out the following based on the decision 
       * to make all the fields optional
       * This can be turned on in future. So, DONOT delete
       *************************************************************/
        // if (this.state.symptoms_started == undefined || this.state.symptoms_started == "") {
        //   const status = true
        //   this.props.guidanceActions.updateSymptomsStartedError(status)
        //   return;
        // }

        var symptomOptionsToDispatch = {
          symptoms_started: this.state.symptoms_started,
          consulted_doctor: this.state.consulted_doctor,
        }
        this.props.guidanceActions.storeSymptomsOptions(symptomOptionsToDispatch)
      }

    }
    // TAB 3
    else if (this.props.vapingProgressTabIndex == 3) {

      /************************************************************
       * Commenting out the following based on the decision 
       * to make all the fields optional
       * This can be turned on in future. So, DONOT delete
       *************************************************************/
      // if (this.state.age && this.state.age != "") {
      //   var verifyAge = validator.isInt(this.state.age);

      //   if (!(verifyAge)) {
      //     const status = true
      //     this.props.guidanceActions.updateVerifyAgeFormatError(status)
      //     return;
      //   }

      //   if (verifyAge && this.state.age.toString().length > 3) {
      //     const status = true
      //     this.props.guidanceActions.updateVerifyAgeFormatError(status)
      //     return;
      //   }

      // }

      var userDetailstoDispatch = {
        age: this.state.age,
        sex: this.state.sex,
        chronic_condition: this.state.chronic_condition_flag,
        chronic_condition_desc: this.state.chronic_condition_desc
      }

      this.props.guidanceActions.storeUserDetails(userDetailstoDispatch);

    }
    // TAB 4
    else if (this.props.vapingProgressTabIndex == 4) {

      /************************************************************
       * Commenting out the following based on the decision 
       * to make all the fields optional
       * This can be turned on in future. So, DONOT delete
       *************************************************************/
      // if (this.state.vapingProductsList == undefined || this.state.vapingProductsList == "") {
      //   const status = true
      //   this.props.guidanceActions.updateVapingProductsListError(status);

      //   return;
      // }

      var productDetailsToDispatch = {
        where_did_you_buy: this.state.vapingProductsBroughtFromList,
        what_is_vaping_prod_used: this.state.vapingProductsList,
        flavor: this.state.vapingFlavor,
        vape_brands: this.state.vapingBrands,
        vaping_products_brought_from_other_text: this.state.vapingProductsOtherText,
        vaping_products_other_text: this.state.vapingProductUsageOtherText,
        vaping_brands_other_text: this.state.vapingBrandsOtherText,
        homemade_ingredients: this.state.vapingIngredientsHomemade,
        unusual_smell_flag: this.state.unusualSmellFromProduct_flag,
        unusual_characters_desc: this.state.unusualSmellFromProduct_desc
      }

      this.props.guidanceActions.storeProductDetails(productDetailsToDispatch);
    }
    // TAB 5
    else if (this.props.vapingProgressTabIndex == 5) {

      // VERIFY EMAIL FORMAT
      if (this.state.email && this.state.email != "") {
        var verifyEmailFormat = validator.isEmail(this.state.email);

        if (!(verifyEmailFormat)) {
          const status = true
          this.props.guidanceActions.updateVerifyEmailFormatError(status)
          return;
        }
      }

      // VERIFY ZIPCODE FORMAT
      if (this.state.zipcode && this.state.zipcode != "") {

      }

      var additionalDetailsToDispatch = {
        zipcode: this.state.zipcode,
        contact_consent_flag: this.state.contactConsentFlag,
        email: this.state.email,
        additional_comments: this.state.additionalComments
      }

      this.props.guidanceActions.storeAdditionalDetails(additionalDetailsToDispatch);

      this.setSurveyDataToPost().then(() => {
        this.props.history.push({
          pathname: '/results',
          state: { surveyData: this.state }
        })
      })



    }
    this.props.guidanceActions.vapingSurveyMoveNext();

    // this.props.guidanceActions.feverRecommendationsMoveNext();
  };

  moveBack = () => {
    window.scrollTo(0, 0);
    // console.log("Current Symptoms Display Flag **** BACK **** ----> ", this.props.symptomsDisplayFlag)
    if (this.props.vapingProgressTabIndex == 2 && this.state.displaySymptomsOptionsTab == true) {
      this.setState(prevState => ({
        displaySymptomsOptionsTab: !prevState.displaySymptomsOptionsTab
      }));
      this.props.guidanceActions.updateSymptomsDisplayFlag(true);
      return;
    }

    this.props.guidanceActions.vapingSurveyMoveBack();
    // this.props.guidanceActions.feverRecommendationsMoveBack();
  };

  setSurveyDataToPost = () => {
    return new Promise(resolve => {
      var surveyDetails = {};

      surveyDetails.startedVapingOption = this.state.whenStartedVapingOption;
      surveyDetails.typicalVapingOption = this.state.typicalVapingPerDayOption;
      surveyDetails.lastVaped = this.state.lastVapedOption;
      surveyDetails.symptoms = this.state.selectedSymptoms;
      surveyDetails.symptoms_started = this.state.symptoms_started;
      surveyDetails.consulted_doctor = this.state.consulted_doctor;
      surveyDetails.age = this.state.age;
      surveyDetails.chronic_condition = this.state.chronic_condition_flag;
      surveyDetails.chronic_condition_desc = this.state.chronic_condition_desc;
      surveyDetails.sex = this.state.sex;
      surveyDetails.product_brought_from = this.state.vapingProductsBroughtFromList;
      surveyDetails.vaping_products_used = this.state.vapingProductsList;
      surveyDetails.vaping_brands = this.state.vapingBrands;
      surveyDetails.vaping_flavor = this.state.vapingFlavor;
      surveyDetails.vaping_products_brought_from_other_text = this.state.vapingProductsOtherText;
      surveyDetails.vaping_products_other_text = this.state.vapingProductUsageOtherText;
      surveyDetails.vaping_brands_other_text = this.state.vapingBrandsOtherText;
      surveyDetails.homemade_ingredients = this.state.vapingIngredientsHomemade;
      surveyDetails.unusual_smell_flag = this.state.unusualSmellFromProduct_flag;
      surveyDetails.unusual_characters_desc = this.state.unusualSmellFromProduct_desc;
      surveyDetails.zipcode = this.state.zipcode;
      surveyDetails.email = this.state.email;
      surveyDetails.contact_consent_flag = this.state.contactConsentFlag;
      surveyDetails.additional_comments = this.state.additionalComments;
      surveyDetails.referral = "";

      var sessionId = SessionTracker.getSessionID();
      var referralName = "";
      var userCaptured = sessionStorage.getItem("userCaptured");
      if (!userCaptured || userCaptured == "") {
        var sessionId = SessionTracker.getSessionID();
        this.props.guidanceActions
          .captureNewUserSession(sessionId, referralName)
          .then(resp => {
            console.log("captureNewUserSession:" + resp);
            sessionStorage.setItem("userCaptured", "true");
          })
          .catch(error => {
            //
          });
      } else {
        // this.props.guidanceActions.resetFeverRecommendations(referralName);
      }
      this.props.guidanceActions.postSurveyData(surveyDetails, sessionId);
      resolve();
    });
  }

  componentDidMount() {
    // console.log('componentDidMount')
  }

  componentWillReceiveProps(nextProps) {
    // console.log("componentWillReceiveProps ==> ", nextProps);
    if (nextProps.vapingProgressTabIndex == 1) {
      this.setState({
        // selectedSymptoms: [],
        // whenStartedVapingOption: "",
        // typicalVapingPerDayOption: "",
        // lastVapedOption: "",
        // vapingHistoryDetails: []
      });
    } else if (nextProps.vapingProgressTabIndex == 2) {
      this.setState({
        // symptoms_started: "",
        // consulted_doctor: ""
      })
    } else if (nextProps.vapingProgressTabIndex == 3) {
      this.setState({
        // age: "",
        // sex: "",
        // chronic_condition_desc: "",
        // chronic_condition_flag: ""
      })
    } else if (nextProps.vapingProgressTabIndex == 4) {
      this.setState({
        // vapingProductsBroughtFromList: [],
        // vapingProductsList: [],
        // vapingBrands: [],
        // vapingFlavor: "",
        // vapingIngredientsHomemade: "",
        // vapingProductsOtherText: "",
        // vapingProductUsageOtherText: "",
        // vapingBrandsOtherText: "",
        // unusualSmellFromProduct_flag: "",
        // unusualSmellFromProduct_desc: ""
      })
    } else if (nextProps.vapingProgressTabIndex == 5) {
      this.setState({
        // zipcode: "",
        // email: "",
        // contactConsentFlag: "",
        // additionalComments: ""
      })
    }
  }

  componentWillUpdate(nextProps) {
    // console.log('componentWillUpdate')
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log('componentDidUpdate')
  }

  selectSymptom = symptom => {
    this.setState({
      selectedSymptoms: [symptom].concat(this.state.selectedSymptoms)
    });
    // console.log(this.state.selectedSymptoms)
  };
  unselectSymptom = symptom => {
    this.setState({
      selectedSymptoms: this.state.selectedSymptoms.filter(
        sym => sym !== symptom
      )
    });
    // console.log(this.state.selectedSymptoms)
  };


  /* ********************************************************
              PARTICIPANT Vaping History Options
  *************************************************************/
  setWhenVapedOption = e => {
    // this.setState({ whenStartedVapingOption: e })

    this.setState(prevState => ({
      whenStartedVapingOption: e
    }));

    console.log("Set when Started option -----> ", this.state.whenStartedVapingOption)
  }



  setTypicalVapingPerDayOption = e => {
    this.setState({ typicalVapingPerDayOption: e })
  }
  setLastVapedOption = e => {
    this.setState({ lastVapedOption: e })
  }

  /* ********************************************************
              PARTICIPANT Symptom Options
  *************************************************************/
  setSymptomDetailOption = e => {
    this.setState({ symptoms_started: e })
  }
  setConsultedDoctorOption = e => {
    this.setState({ consulted_doctor: e })
  }

  /* ********************************************************
              PARTICIPANT Personal Details
  *************************************************************/

  setParticipantAge = e => {
    this.setState({ age: e });
  };

  setParticipantSex = e => {
    this.setState({ sex: e });
  };

  setChronicConditionFlag = e => {
    this.setState({ chronic_condition_flag: e })
  }

  setChronicConditionDesc = e => {
    this.setState({ chronic_condition_desc: e })
  }

  /* ********************************************************
              Product Details
  *************************************************************/

  setProductOtherText = e => {
    this.setState({
      vapingProductsOtherText: e
    })
  }

  setProductUsageOtherText = e => {
    this.setState({
      vapingProductUsageOtherText: e
    })
  }

  setVapingBrandOtherText = e => {
    this.setState({
      vapingBrandsOtherText: e
    })
  }

  setVapingProductsBroughtFrom = e => {
    this.setState({
      vapingProductsBroughtFromList: e
    })
  }

  setVapingProductsUsed = e => {
    this.setState({
      vapingProductsList: e
    })
  }

  setVapingBrands = e => {
    this.setState({
      vapingBrands: e
    })
  }

  setVapingFlavor = e => {
    this.setState({
      vapingFlavor: e
    })
  }

  setVapingIngredients = e => {
    this.setState({
      vapingIngredientsHomemade: e
    })
  }

  setUnusualCharactersFlag = e => {
    this.setState({
      unusualSmellFromProduct_flag: e
    })
  }

  setUnusualCharactersDesc = e => {
    this.setState({
      unusualSmellFromProduct_desc: e
    })
  }

  /* ********************************************************
              Additional Details
  *************************************************************/

  setEmail = e => {
    this.setState({ email: e });
  };

  setZipcode = e => {
    this.setState({ zipcode: e });
  };

  setContactConsentFlag = e => {
    this.setState({ contactConsentFlag: e });
  }

  setAdditionalComments = e => {
    this.setState({ additionalComments: e });
  }

  render() {
    return (
      <div className="secondary">
        <Navbar />
        <div className="secondary-content">
          <div className="container">
            <div className="row">
              <div className="col text-center">
                <div className="form-wrapper mt-4">
                  <ProgressTabs
                    selectSymptom={this.selectSymptom}
                    unselectSymptom={this.unselectSymptom}
                    setParticipantAge={this.setParticipantAge}
                    setParticipantSex={this.setParticipantSex}
                    setChronicConditionFlag={this.setChronicConditionFlag}
                    setChronicConditionDesc={this.setChronicConditionDesc}
                    setEmail={this.setEmail}
                    setZipcode={this.setZipcode}
                    setContactConsentFlag={this.setContactConsentFlag}
                    setAdditionalComments={this.setAdditionalComments}
                    setWhenVapedOption={this.setWhenVapedOption}
                    setTypicalVapingPerDayOption={this.setTypicalVapingPerDayOption}
                    setLastVapedOption={this.setLastVapedOption}
                    setSymptomDetailOption={this.setSymptomDetailOption}
                    setConsultedDoctorOption={this.setConsultedDoctorOption}
                    setProductOtherText={this.setProductOtherText}
                    setProductUsageOtherText={this.setProductUsageOtherText}
                    setVapingProductsBroughtFrom={this.setVapingProductsBroughtFrom}
                    setVapingProductsUsed={this.setVapingProductsUsed}
                    setVapingBrands={this.setVapingBrands}
                    setVapingBrandOtherText={this.setVapingBrandOtherText}
                    setVapingFlavor={this.setVapingFlavor}
                    setVapingIngredients={this.setVapingIngredients}
                    setUnusualCharactersFlag={this.setUnusualCharactersFlag}
                    setUnusualCharactersDesc={this.setUnusualCharactersDesc}
                  />
                </div>
              </div>
            </div>
            <div className="row mt-3 mb-5 pb-5">
              <div className="col text-center d-flex">
                {this.props.vapingProgressTabIndex &&
                  this.props.vapingProgressTabIndex > 1 && (
                    <Button
                      rounded
                      outline
                      color="blue"
                      className="mr-auto btn-sm-width"
                      onClick={this.moveBack}
                    >
                      Back
                      {/* <FormattedMessage
                        id="Survey.BackButton"
                        defaultMessage="Back"
                      /> */}
                    </Button>
                  )}

                {this.props.vapingProgressTabIndex
                  && this.props.vapingProgressTabIndex == 2
                  && this.state.selectedSymptoms
                  && this.state.selectedSymptoms.length == 0
                  && (
                    <Button rounded color="primary blue" className="ml-auto" onClick={this.moveNext}>
                      I don't have any symptoms
                    </Button>
                  )
                }
                {this.props.vapingProgressTabIndex
                  && this.props.vapingProgressTabIndex == 2
                  && this.state.selectedSymptoms
                  && this.state.selectedSymptoms.length > 0 &&
                  (
                    <Button rounded color="primary blue" className="ml-auto btn-sm-width" onClick={this.moveNext}>
                      Next
                    </Button>
                  )
                }
                {this.props.vapingProgressTabIndex &&
                  this.props.vapingProgressTabIndex < 6 && this.props.vapingProgressTabIndex != 2 && (
                    <Button
                      rounded
                      color="primary blue"
                      className="ml-auto btn-sm-width"
                      onClick={this.moveNext}
                    >
                      Next
                      {/* <FormattedMessage
                        id="Survey.NextButton"
                        defaultMessage="Next"
                      /> */}
                    </Button>
                  )}
                {this.props.vapingProgressTabIndex &&
                  this.props.vapingProgressTabIndex == 6 && (
                    <a
                      className="btn btn-primary btn-rounded ml-auto btn-sm-width"
                      href="/survey"
                    >
                      Start Over
                      {/* <FormattedMessage
                        id="Survey.StartButton"
                        defaultMessage="Start Over"
                      /> */}
                    </a>
                  )}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

// export default CheckFever;

function mapStateToProps(state) {
  console.log("State values => ", state)
  return {
    vapingProgressTabIndex:
      state.vapingSurveyState.vapingProgressTabIndex,
    historyDetails: state.vapingSurveyState.historyDetails,
    symptomDetails: state.vapingSurveyState.symptomDetails,
    symptomsOptions: state.vapingSurveyState.symptomOptions,
    participantDetails: state.vapingSurveyState.participantDetails,
    productDetails: state.vapingSurveyState.productDetails,
    referral: state.vapingSurveyState.referral,
    additionalDetails: state.vapingSurveyState.additionalDetails,
    symptomsDisplayFlag: state.vapingSurveyState.symptomsDisplayFlag
  };
}

function mapDispatchToProps(dispatch) {
  return {
    guidanceActions: bindActionCreators(GuidanceActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Survey);
