import React, { Component } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Faq extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="secondary">
        <Navbar />
        <div className="mt-5 mb-5 pt-5 pb-5 secondary-content">
          <div className="container">
            <div className="row">
              <div className="col-8 mr-auto ml-auto mt-5">
                <h3 class="text-center mb-4">Frequently Asked Questions</h3>
                <h4 className="mt-5 mb-3">Why should I take the survey?</h4>
                <p>Filling out the survey will help us do the following:</p>
                <ul>
                  <li>Find new cases of lung disease.</li>
                  <li>Find what is causing vaping related lung disease.</li>
                  <li>Educate people on what is happening in their area.</li>
                </ul>
                <h4 className="mt-5 mb-3">Do I need to stop vaping?</h4>
                <p>The CDC recommends that people should not:</p>
                <ul>
                  {" "}
                  <li>Use vaping products that contain THC.</li>
                  <li>Buy vaping products off the street.</li>
                </ul>
                <p>
                  Since the cause of lung injury is not known, the only way to
                  make sure you are not at risk is to stop using vaping
                  products.
                </p>
                <p>
                  For the most up to date information see the{" "}
                  <a
                    className="link"
                    href="https://www.cdc.gov/tobacco/basic_information/e-cigarettes/severe-lung-disease/need-to-know/index.html"
                    target="_blank"
                  >
                    CDC’s list of recommendations{" "}
                    <FontAwesomeIcon icon={["far", "arrow-right"]} />
                  </a>
                </p>

                <h4 className="mt-5 mb-3">
                  What should I do if I am currently vaping marijuana/THC for
                  medical use?
                </h4>
                <p>
                  The CDC recommends that people should not use vaping products
                  that contain THC. If you have recently used a THC-containing
                  vaping product and you have symptoms such as cough, shortness
                  of breath,chest pain, nausea, vomiting, abdominal pain,
                  diarrhea, fever, chills, or weight loss, see a healthcare
                  provider.
                </p>
                <p>
                  Talk with your healthcare provider about other available
                  options.
                </p>
                <p>
                  For the most up to date information see the{" "}
                  <a
                    href="https://www.cdc.gov/tobacco/basic_information/e-cigarettes/severe-lung-disease/need-to-know/index.html#questions"
                    target="_blank"
                    className="link"
                  >
                    CDC’s website{" "}
                    <FontAwesomeIcon icon={["far", "arrow-right"]} />
                  </a>
                </p>

                <h4 className="mt-5 mb-3">
                  What should I do if I have used e-cigarettes and have
                  symptoms?
                </h4>
                <p>
                  See your doctor if you have symptoms such as cough, shortness
                  of breath, chest pain, nausea, vomiting, abdominal pain,
                  diarrhea, fever, chills, or weight loss. You can also call
                  your local poison control center at 1-800-222-1222.
                </p>
                <p>
                  You can submit a report of any unexpected health or product
                  issues related to tobacco or e-cigarette products to the FDA
                  using the online Safety Reporting Portal.
                </p>
                <p>
                  For the most up to date information see the{" "}
                  <a
                    href="https://www.cdc.gov/tobacco/basic_information/e-cigarettes/severe-lung-disease/need-to-know/index.html#questions"
                    target="_blank"
                    className="link"
                  >
                    CDC’s website{" "}
                    <FontAwesomeIcon icon={["far", "arrow-right"]} />
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Faq;
