import * as ActionTypes from '../actions/action-types';
import * as KidsMDApi from '../api/kidsmd-api'
import { func } from 'prop-types';

export function postSurveyData(surveyDetails, sessionId) {
  return function (dispatch) {
    return KidsMDApi.postVapingSurveyData(surveyDetails, sessionId).then(recommendations => {
      dispatch({ type: ActionTypes.POST_SURVEY_DATA_SUCESS, recommendations });
    }).catch(error => {
      throw (error);
    })
  }
}

export function captureNewUserSession(sessionId, referral) {
  return function (dispatch) {
    return KidsMDApi.captureNewUserSession(sessionId, referral).then(response => {
      dispatch({ type: ActionTypes.NEW_USER_SESSION_SUCCESS, response, referral });
    }).catch(error => {
      throw (error);
    })
  }
}

export function storeHistoryDetails(historyDetails) {
  return function (dispatch) {
    dispatch({ type: ActionTypes.STORE_VAPING_HISTORY_DETAILS, historyDetails });
  }
}

export function storeSymptoms(symptoms) {
  return function (dispatch) {
    dispatch({ type: ActionTypes.STORE_VAPING_SYMPTOMS_DETAILS, symptoms });
  }
}

export function storeSymptomsOptions(symptomsOptions) {
  return function (dispatch) {
    dispatch({ type: ActionTypes.STORE_VAPING_SYMPTOMS_OPTIONS, symptomsOptions });
  }
}


export function storeUserDetails(userDetails) {
  return function (dispatch) {
    dispatch({ type: ActionTypes.STORE_USER_DETAILS, userDetails });
  }
}

export function storeProductDetails(productDetails) {
  return function (dispatch) {
    dispatch({ type: ActionTypes.STORE_VAPING_PRODUCT_DETAILS, productDetails });
  }
}

export function storeAdditionalDetails(additionalDetails) {
  return function (dispatch) {
    dispatch({ type: ActionTypes.STORE_ADDITIONAL_DETAILS, additionalDetails });
  }
}

export function resetVapingSurvey() {
  return function (dispatch) {
    dispatch({ type: ActionTypes.RESET_VAPING_SURVEY });
  }
}

export function resetFeverRecommendations(referral) {
  return function (dispatch) {
    dispatch({ type: ActionTypes.RESET_FEVER_RECOMMENDATIONS, referral });
  }
}

export function vapingSurveyMoveNext() {
  return function (dispatch) {
    dispatch({ type: ActionTypes.VAPING_SURVEY_MOVE_NEXT });
  }
}

export function vapingSurveyMoveBack() {
  return function (dispatch) {
    dispatch({ type: ActionTypes.VAPING_SURVEY_MOVE_BACK });
  }
}

export function updateLastVapedError(status) {
  return function (dispatch) {
    dispatch({ type: ActionTypes.ERROR_LAST_TIME_VAPED, status })
  }
}

export function updateTypicalDayVapingError(status) {
  return function (dispatch) {
    dispatch({ type: ActionTypes.ERROR_TYPICAL_DAY_VAPING, status })
  }
}

export function updateStartedVapingError(status) {
  return function (dispatch) {
    dispatch({ type: ActionTypes.ERROR_START_VAPING_QST, status })
  }
}

export function updateSymptomsSelectionError(status) {
  return function (dispatch) {
    dispatch({ type: ActionTypes.ERROR_SELECTING_SYMPTOMS, status })
  }
}

export function updateSymptomsStartedError(status) {
  return function (dispatch) {
    dispatch({ type: ActionTypes.ERROR_SELECTING_SYMPTOMS_STARTED_TIME, status })
  }
}

export function updateVapingProductsListError(status) {
  return function (dispatch) {
    dispatch({ type: ActionTypes.ERROR_SELECTING_VAPING_PRODUCTS, status })
  }
}

export function updateVerifyAgeFormatError(status) {
  return function (dispatch) {
    dispatch({ type: ActionTypes.ERROR_VERIFY_AGE_FORMAT, status })
  }
}

export function updateVerifyEmailFormatError(status) {
  return function (dispatch) {
    dispatch({ type: ActionTypes.ERROR_VERIFY_EMAIL_FORMAT, status })
  }
}

export function updateVerifyZipcodeFormatError(status) {
  return function (dispatch) {
    dispatch({ type: ActionTypes.ERROR_VERIFY_ZIPCODE_FORMAT, status })
  }
}

export function updateSymptomsDisplayFlag(flag) {
  return function (dispatch) {
    dispatch({ type: ActionTypes.SYMPTOMS_DISPLAY_FLAG, flag })
  }
}
