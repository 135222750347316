import React from "react";
import fatigue from "../assets/img/symptoms/weight-loss.svg";
import fatiguewhite from "../assets/img/symptoms/fatigue-white.svg";

class SymptomWeightLoss extends React.Component {
  render() {
    return (
      <div className="symptom-group d-flex flex-column justify-content-center">
        <img src={fatigue} className="symptom-icon" alt="Fatigue" />
        <img
          src={fatiguewhite}
          className="symptom-icon white-outline"
          alt="Fatigue"
        />
        <h5 className="mt-3 mb-0">Weight Loss</h5>
      </div>
    );
  }
}

export default SymptomWeightLoss;
