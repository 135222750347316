import * as types from '../actions/action-types';
import initialState from './initialState';

const VapingSurveyReducer = function (state = initialState, action) {

    switch (action.type) {
        case types.STORE_VAPING_HISTORY_DETAILS:

            var stateObj = Object.assign({}, state, {
                historyDetails: {
                    ...state.historyDetails,
                    lastVapedOption: action.historyDetails.lastVapedOption,
                    typicalVapingPerDayOption: action.historyDetails.typicalVapingPerDayOption,
                    whenStartedVapingOption: action.historyDetails.whenStartedVapingOption
                }
            });
            return stateObj;
        case types.STORE_VAPING_SYMPTOMS_DETAILS:

            var stateObj = Object.assign({}, state, {
                symptomDetails: {
                    ...state.symptomDetails,
                    symptoms: action.symptoms,
                }
            });
            return stateObj;
        case types.STORE_VAPING_SYMPTOMS_OPTIONS:
            var stateObj = Object.assign({}, state, {
                symptomsOptions: {
                    ...state.symptomsOptions,
                    symptoms_started: action.symptomsOptions.symptoms_started,
                    consulted_doctor: action.symptomsOptions.consulted_doctor
                }
            });
            return stateObj;
        case types.STORE_USER_DETAILS:
            var stateObj = Object.assign({}, state, {
                participantDetails: {
                    ...state.participantDetails,
                    age: action.userDetails.age,
                    sex: action.userDetails.sex,
                    chronic_condition: action.userDetails.chronic_condition,
                    chronic_condition_desc: action.userDetails.chronic_condition_desc
                }
            });
            return stateObj;
        case types.STORE_ADDITIONAL_DETAILS:
            var stateObj = Object.assign({}, state, {
                additionalDetails: {
                    ...state.additionalDetails,
                    zipcode: action.additionalDetails.zipcode,
                    contact_consent_flag: action.additionalDetails.contact_consent_flag,
                    email: action.additionalDetails.email,
                    additional_comments: action.additionalDetails.additional_comments
                }
            });
            return stateObj;
        case types.STORE_VAPING_PRODUCT_DETAILS:

            var stateObj = Object.assign({}, state, {
                productDetails: {
                    ...state.productDetails,
                    where_did_you_buy: action.productDetails.where_did_you_buy,
                    what_is_vaping_prod_used: action.productDetails.what_is_vaping_prod_used,
                    flavor: action.productDetails.flavor,
                    vaping_products_brought_from_other_text: action.productDetails.vaping_products_brought_from_other_text,
                    vaping_products_other_text: action.productDetails.vaping_products_other_text,
                    vaping_brands_other_text: action.productDetails.vaping_brands_other_text,
                    homemade_ingredients: action.productDetails.homemade_ingredients,
                    vape_brands: action.productDetails.vape_brands,
                    unusual_smell_flag: action.productDetails.unusual_smell_flag,
                    unusual_characters_desc: action.productDetails.unusual_characters_desc
                }
            });
            return stateObj;

        case types.VAPING_SURVEY_MOVE_NEXT:
            return Object.assign({}, state, { vapingProgressTabIndex: state.vapingProgressTabIndex + 1 });

        case types.VAPING_SURVEY_MOVE_BACK:
            return Object.assign({}, state, { vapingProgressTabIndex: state.vapingProgressTabIndex - 1 });

        case types.ERROR_LAST_TIME_VAPED:
            var stateObj = Object.assign({}, state, {
                formErrors: {
                    ...state.formErrors,
                    err_last_vaped: action.status
                }
            });
            return stateObj;

        case types.ERROR_TYPICAL_DAY_VAPING:
            var stateObj = Object.assign({}, state, {
                formErrors: {
                    ...state.formErrors,
                    err_typical_day_vaping: action.status
                }
            });
            return stateObj;

        case types.ERROR_START_VAPING_QST:
            var stateObj = Object.assign({}, state, {
                formErrors: {
                    ...state.formErrors,
                    err_started_vaping: action.status
                }
            });
            return stateObj;

        case types.ERROR_SELECTING_SYMPTOMS:
            var stateObj = Object.assign({}, state, {
                formErrors: {
                    ...state.formErrors,
                    err_selecting_symptoms: action.status
                }
            });
            return stateObj;

        case types.ERROR_SELECTING_SYMPTOMS_STARTED_TIME:
            var stateObj = Object.assign({}, state, {
                formErrors: {
                    ...state.formErrors,
                    err_selecting_symptoms_started_time: action.status
                }
            });
            return stateObj;

        case types.ERROR_SELECTING_VAPING_PRODUCTS:
            var stateObj = Object.assign({}, state, {
                formErrors: {
                    ...state.formErrors,
                    err_selecting_vaping_products: action.status
                }
            });
            return stateObj;

        case types.ERROR_VERIFY_AGE_FORMAT:
            var stateObj = Object.assign({}, state, {
                formErrors: {
                    ...state.formErrors,
                    err_age_format: action.status
                }
            });
            return stateObj;

        case types.ERROR_VERIFY_EMAIL_FORMAT:
            var stateObj = Object.assign({}, state, {
                formErrors: {
                    ...state.formErrors,
                    err_email_format: action.status
                }
            });
            return stateObj;

        case types.ERROR_VERIFY_ZIPCODE_FORMAT:
            var stateObj = Object.assign({}, state, {
                formErrors: {
                    ...state.formErrors,
                    err_zipcode_format: action.status
                }
            });
            return stateObj;

        case types.SYMPTOMS_DISPLAY_FLAG:
            return Object.assign({}, state, { symptomsDisplayFlag: action.flag });

        case types.GET_FEVER_RECOMMENDATIONS_SUCCESS:
            return Object.assign({}, state, { feverRecommendations: action.recommendations });

        case types.RESET_VAPING_SURVEY:
            return Object.assign({}, state, { vapingSurveyState: {}, symptomDetails: {}, vapingProgressTabIndex: 1, symptomsDisplayFlag: true });

        case types.NEW_USER_SESSION_SUCCESS:
            return Object.assign({}, state, { referral: action.referral });
    }

    return state;
}
export default VapingSurveyReducer
