import React, { Component } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="secondary">
        <Navbar />
        <div className="mt-5 mb-5 pt-5 pb-5 secondary-content">
          <div className="container">
            <div className="row">
              <div className="col-8 mr-auto ml-auto mt-5">
	    <h3 class="text-center mb-4">Contact Us</h3>
	    <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScEGQXxTtCQGvKxW3-JJq3EZzGOSqOiFzYTepm5CijeyztvkA/viewform?embedded=true" width="100%" height="645" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
	    </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Contact;
