import React from "react";
import diarrhea from "../assets/img/symptoms/diarrhea.svg";
import diarrheawhite from "../assets/img/symptoms/diarrhea-white.svg";

class SymptomDiarrhea extends React.Component {
  render() {
    return (
      <div className="symptom-group d-flex flex-column justify-content-center">
        <img src={diarrhea} className="symptom-icon" alt="Diarrhea" />
        <img
          src={diarrheawhite}
          className="symptom-icon white-outline"
          alt="Diarrhea"
        />
        <h5 className="mt-3 mb-0">Diarrhea</h5>
      </div>
    );
  }
}

export default SymptomDiarrhea;
