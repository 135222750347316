import axios from 'axios';

const KIDSMD_API_URL = process.env.REACT_APP_KIDSMD_SERVICES_BASE_URL + 'vaping-api';

// Post survey Data

export function postVapingSurveyData(vapingSurveyData, sessionId) {
  var surveyData = {}

  var outgoingAge = 0


  if (vapingSurveyData.age != '') {
    if (vapingSurveyData.age == "under_18") {
      outgoingAge = 18
    } else if (vapingSurveyData.age == "18-24") {
      outgoingAge = 1824
    } else if (vapingSurveyData.age == "24-35") {
      outgoingAge = 2435
    } else if (vapingSurveyData.age == "35-45") {
      outgoingAge = 3545
    } else if (vapingSurveyData.age == "45-54") {
      outgoingAge = 4554
    } else if (vapingSurveyData.age == "55_and_above") {
      outgoingAge = 60
    }
    // switch (vapingSurveyData.age.trim()) {
    //   case "under_18": {
    //     outgoingAge = 18
    //   }
    //   case "18-24": {
    //     outgoingAge = 1824
    //   }
    //   case "24-35": {
    //     outgoingAge = 2435
    //   }
    //   case "35-45": {
    //     outgoingAge = 3545
    //   }
    //   case "45-54": {
    //     outgoingAge = 4554
    //   }
    //   case "55_and_above": {
    //     outgoingAge = 60
    //   }
    // }

  }
  var surveyData = "startedVapingOption=" + vapingSurveyData.startedVapingOption
    + "&typicalVapingOption=" + vapingSurveyData.typicalVapingOption
    + "&lastVaped=" + vapingSurveyData.lastVaped
    + "&symptoms=" + vapingSurveyData.symptoms
    + "&symptoms_started=" + vapingSurveyData.symptoms_started
    + "&consulted_doctor=" + vapingSurveyData.consulted_doctor
    + "&age=" + outgoingAge
    + "&sex=" + vapingSurveyData.sex
    + "&chronic_condition=" + vapingSurveyData.chronic_condition
    + "&chronic_condition_desc=" + vapingSurveyData.chronic_condition_desc
    + "&product_brought_from=" + vapingSurveyData.product_brought_from
    + "&vaping_products_used=" + vapingSurveyData.vaping_products_used
    + "&vaping_brands=" + vapingSurveyData.vaping_brands
    + "&vaping_flavor=" + vapingSurveyData.vaping_flavor
    + "&vaping_products_brought_from_other_text=" + vapingSurveyData.vaping_products_brought_from_other_text
    + "&vaping_products_other_text=" + vapingSurveyData.vaping_products_other_text
    + "&vaping_brands_other_text=" + vapingSurveyData.vaping_brands_other_text
    + "&homemade_ingredients=" + vapingSurveyData.homemade_ingredients
    + "&unusual_smell_flag=" + vapingSurveyData.unusual_smell_flag
    + "&unusual_characters_desc=" + vapingSurveyData.unusual_characters_desc
    + "&zipcode=" + vapingSurveyData.zipcode
    + "&email=" + vapingSurveyData.email
    + "&contact_consent_flag=" + vapingSurveyData.contact_consent_flag
    + "&additional_comments=" + vapingSurveyData.additional_comments
    + "&referral=" + vapingSurveyData.referral
    + "&sessionId=" + sessionId
    + "&_" + (new Date());

  console.log("Vaping survey data to post ====> ", surveyData)

  return axios.post(KIDSMD_API_URL + "/vapingHealthTriage", surveyData)
    .then(response => {
      return response.data;
    }).catch(function (error) {
      console.log(error);
      return error;
    });
}

export function captureNewUserSession(sessionId, referral) {
  var data = "sessionId=" + sessionId + "&referral=" + referral + "&_" + (new Date());
  console.log("Data for Captured user API ----> ", data)
  return axios.post(KIDSMD_API_URL + "/captureVapingUserSession", data)
    .then(response => {
      if (response) {
        return response.data;
      } else {
        return '';
      }
    }).catch(function (error) {
      console.log(error);
      return error;
    });
}

