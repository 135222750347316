import React from "react";
import cough from "../assets/img/symptoms/coughing-blood.svg";
import coughwhite from "../assets/img/symptoms/cough-white.svg";

class SymptomCoughingBlood extends React.Component {
  render() {
    return (
      <div className="symptom-group d-flex flex-column justify-content-center">
        <img src={cough} className="symptom-icon" alt="Cough" />
        <img
          src={coughwhite}
          className="symptom-icon white-outline"
          alt="Cough"
        />
        <h5 className="mt-3 mb-0">Coughing up blood</h5>
      </div>
    );
  }
}

export default SymptomCoughingBlood;
