import React, { Component } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailShareButton
} from "react-share";

import { Fa, Button, FormInline, Input } from "mdbreact";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class ShareLinks extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const shareUrl = "http://youvape.org";
    const title =
      "Have you vaped before? Take a short survey to help Boston Children's Hospital understand the health effects of vaping.";
    const subject = "Have you vaped before?";

    return (
      <div className="wrapper light pb-5 pt-5">
        <div className="container">
          <div className="row no-gutters">
            <div className="col text-center">
              <h3 className="mb-3 pb-3">Share the survey with friends</h3>
              <div className="d-flex justify-content-center">
                <FacebookShareButton url={shareUrl} quote={title}>
                  <span className="circle-wrapper med-circle dark ml-2 mr-2 ml-sm-4 mr-sm-4">
                    <FontAwesomeIcon
                      className="text-white"
                      icon={["fab", "facebook-f"]}
                      size="lg"
                    />
                  </span>
                </FacebookShareButton>

                <TwitterShareButton url={shareUrl} title={title}>
                  <span className="circle-wrapper med-circle dark ml-2 mr-2 ml-sm-4 mr-sm-4">
                    <FontAwesomeIcon
                      className="text-white"
                      icon={["fab", "twitter"]}
                      size="lg"
                    />
                  </span>
                </TwitterShareButton>
                <EmailShareButton url={shareUrl} subject={subject} body={title}>
                  <span className="circle-wrapper med-circle dark ml-2 mr-2 ml-sm-4 mr-sm-4">
                    <Fa className="text-white" icon="envelope" size="lg" />
                  </span>
                </EmailShareButton>

                <LinkedinShareButton
                  url={shareUrl}
                  title={title}
                  windowWidth={750}
                  windowHeight={600}
                >
                  <span className="circle-wrapper med-circle dark ml-2 mr-2 ml-sm-4 mr-sm-4">
                    <FontAwesomeIcon
                      className="text-white"
                      icon={["fab", "linkedin-in"]}
                      size="lg"
                    />
                  </span>
                </LinkedinShareButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ShareLinks;
