import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Fa, Button, FormInline, Input } from "mdbreact";
import step1 from "../assets/img/svg/survey-icon.svg";
import step2 from "../assets/img/svg/guidance-icon.svg";
import step3 from "../assets/img/svg/contribute-icon.svg";
import bchlogo from "../assets/img/bch-logo.svg";
import hmslogo from "../assets/img/hms-logo.svg";
import hmlogo from "../assets/img/hm-logo.svg";
import Navbar from "./Navbar";
import ShareLinks from "./ShareLinks";
import Footer from "./Footer";
import * as SessionTracker from "../util/session-tracker";
import * as GuidanceActions from "../actions/guidance-actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//import queryString from "query-string";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal14: false,
      temperature: 0,
      temperatureUnit: "F",
      signupSuccess: false
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.props.guidanceActions.resetVapingSurvey();
  }

  // componentDidMount() {
  //   var referralName = "";
  //   if (this.props) {
  //     console.log("==> REFERRAL: " + this.props.referral);
  //     console.log(this.props.location);
  //     referralName = this.props.referral;
  //     if (this.props.location && this.props.location.search) {
  //       const values = queryString.parse(this.props.location.search);
  //       console.log("==> REFERRAL query: " + values.referral);
  //       referralName = values.referral;
  //     }
  //   }

  //   console.log("home componentDidMount");
  //   var userCaptured = sessionStorage.getItem("userCaptured");
  //   if (!userCaptured || userCaptured == "") {
  //     var sessionId = SessionTracker.getSessionID();
  //     this.props.guidanceActions
  //       .captureNewUserSession(sessionId, referralName)
  //       .then(resp => {
  //         console.log("captureNewUserSession:" + resp);
  //         sessionStorage.setItem("userCaptured", "true");
  //       })
  //       .catch(error => {
  //         //
  //       });
  //   } else {
  //     this.props.guidanceActions.resetFeverRecommendations(referralName);
  //   }
  // }

  toggle(nr) {
    let modalNumber = "modal" + nr;
    this.setState({
      [modalNumber]: !this.state[modalNumber]
    });
  }
  signupSuccessClick = () => {
    this.setState({ signupSuccess: true });
  };

  handleSubmit(event) {
    event.preventDefault();
    console.log("Pushing to Survey Page ");
    this.props.history.push("/survey");
  }

  render() {
    return (
      <div>
        <Navbar />
        <div className="banner-bg d-flex align-items-center">
          <div className="container">
            <div className="overlay" />
            <div className="row">
              <div className="col">
                <h4 className="mb-3">
                  Help us understand the health effects of vaping.
                </h4>
                <h1 className="mb-4">Have you vaped before?</h1>
                <Button
                  className="blue mt-0 mb-0 ml-0 mr-3"
                  rounded
                  color="primary"
                  size="lg"
                  onClick={this.handleSubmit}
                >
                  Start the survey
                </Button>
              </div>
            </div>
          </div>
        </div>

        <ShareLinks />

        <div className="wrapper pb-5 pt-5">
          <div className="container pt-5 pb-5">
            <div className="row no-gutters">
              <div className="col text-center col-md-10 col-12 offset-md-1">
                <h2 className="light mb-0">
                  Our goal is to figure out the link between vaping and lung
                  injury so that vaping can remain safe.
                </h2>
              </div>
            </div>
          </div>
        </div>

        <div className="wrapper light pb-5 pt-5">
          <div className="container">
            <div className="row no-gutters">
              <div className="col text-center">
                <h3 className="mb-3 pb-3">About YouVape</h3>
              </div>
            </div>
            <div className="row">
              <div className="col-md-10 offset-md-1 col-sm-12">
                <h4 className="mb-3">
                  YouVape was developed at Boston Children's Hospital in
                  response to our article published in the{" "}
                  <a
                    href="https://www.nejm.org/doi/full/10.1056/NEJMc1912818"
                    target="_blank"
                    className="link"
                  >
                    New England Journal of Medicine
                  </a>
                  . YouVape consists of a short, 2-5 minute survey, where will
                  ask you about your health and vaping history. All information
                  you provide is anonymous and will be used by Boston Children's
                  Hospital to help understand lung injury associated with
                  vaping.
                </h4>
                <h4>
                  We are continuing to monitor the outbreak using informal
                  online digital data sources. View an update on the current
                  number of{" "}
                  <a href="https://yhswen.shinyapps.io/vaping" className="link" target="_blank">
                    vaping related lung injury cases{" "}
                    <FontAwesomeIcon icon={["far", "arrow-right"]} />
                  </a>
                </h4>
              </div>
            </div>
          </div>
        </div>

        <div className="wrapper pt-5 pb-5">
          <div className="container">
            <div className="row">
              <div className="col-xs-1 col-sm-4 text-center">
                <img src={bchlogo} className="logo-list" alt="logo" />
              </div>
              <div className="col-xs-1 col-sm-4 text-center mt-4 mb-4 mt-sm-0 mb-sm-0">
                <img src={hmslogo} className="logo-list" alt="logo" />
              </div>
              <div className="col-xs-1 col-sm-4 text-center">
                <img src={hmlogo} className="logo-list" alt="logo" />
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    guidanceActions: bindActionCreators(GuidanceActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home);

// export default Home;
