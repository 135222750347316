import React from "react";
import { render } from 'react-dom';
import { Provider } from 'react-redux'
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import "@fortawesome/fontawesome-pro/css/all.min.css";
import "./assets/scss/mdb.scss";
import App from "./App";
import * as serviceWorker from './serviceWorker';
import configureStore from './configureStore';
import registerServiceWorker from './registerServiceWorker';

const store = configureStore();

// ReactDOM.render(<App />, document.getElementById('root'));

const Routing = () => (
  <Provider store={store}>
    <App />
  </Provider>
)

render(<Routing />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();


