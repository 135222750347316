import React, { Component } from "react";
import { Button, MDBNavbarNav, MDBNavItem, MDBNavLink, Fa, FormInline } from "mdbreact";
class NavbarItem extends Component {
  render() {
    return (
      <MDBNavbarNav right>
        <MDBNavItem>
          <MDBNavLink to="/survey">Survey</MDBNavLink>
        </MDBNavItem>
        <MDBNavItem>
          <MDBNavLink to="/faq">FAQ</MDBNavLink>
        </MDBNavItem>
        <MDBNavItem>
          <MDBNavLink to="/contact">Contact Us</MDBNavLink>
        </MDBNavItem>
      </MDBNavbarNav>
    );
  }
}

export default NavbarItem;
